import React, { useCallback } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { Button, Typography, Box, Chip, DialogActions, Alert } from '@mui/material';
import CloudUploadIcon, { Icon } from '@iconify/react';
import DeleteIcon from '@iconify/react';

interface FileUploaderProps {
    onFilesChange?: (files: File[]) => void;
    maxFiles?: number;
    onCloce: () => void;
    typeAllowed?: { [k: string]: string[] }
    onUpload: (files: File[]) => void;
    maxFileSizeInBytes?: number;
}

export const UploadedFileType: { [key: string]: string } = {
    'application/pdf': 'pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
}

const defaultAllwedFiles = {
    "application/pdf": [".pdf"],
    "application/msexcel": [".xls", ".xlsx"],
    "text/plain": [".txt"],
}

export const DynamicFileUploader: React.FC<FileUploaderProps> = ({ onFilesChange, onCloce, onUpload, typeAllowed, maxFiles = Infinity, maxFileSizeInBytes }) => {
    const [files, setFiles] = React.useState<File[]>([]);
    const [error, setError] = React.useState<string | null>(null);

    const onDrop = useCallback((acceptedFiles: File[], rejections:FileRejection[]) => {

        if(acceptedFiles.length){
            const fileBytes = acceptedFiles[0].size;
            if(maxFileSizeInBytes && fileBytes > maxFileSizeInBytes){
                setError('File size exceeds the maximum allowed size');
                return;
            }
        }

        if(rejections.length > 0 && rejections[0]?.errors?.length > 0 ) {
            rejections[0].errors.forEach(error =>{
                if(error?.code==='file-invalid-type'){
                    setError('Invalid File Type. Please Upload Supported File Type');
                }
            })
            return;
        }

        if (files.length + acceptedFiles.length > maxFiles) {
            setError(`You can only upload up to ${maxFiles} files.`);
            return;
        }
        setError(null);
        const updatedFiles = [...files, ...acceptedFiles];
        setFiles(updatedFiles);
        if (onFilesChange) {
            onFilesChange(updatedFiles);
        }
    }, [files, onFilesChange]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: typeAllowed ?? defaultAllwedFiles, // adjust file types as needed
        multiple: true,
    });

    const handleRemoveFile = (fileToRemove: File) => {
        console.log(fileToRemove, 'file to remove')
        const updatedFiles = files.filter(file => file !== fileToRemove);
        setFiles(updatedFiles);
        if (onFilesChange) {
            onFilesChange(updatedFiles);
        }
    };

    return (
        <>
            <Box
                {...getRootProps()}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '200px',
                    border: '2px dashed #ccc',
                    borderRadius: '4px',
                    padding: '16px',
                    backgroundColor: '#f9f9f9',
                    overflowY: 'auto',
                    position: 'relative',
                    textAlign: 'center',
                }}
            >
                <input {...getInputProps()} />
                <Button
                    variant="contained"
                    component="span"
                    startIcon={<Icon icon={'mdi:CloudUploadIcon'} />}
                    sx={{ marginBottom: '16px' }}
                >
                    Upload Files
                </Button>
                {error && (
                    <Alert severity="error" sx={{ marginBottom: '16px' }}>
                        {error}
                    </Alert>
                )}
                {files.length > 0 && (
                    <Box>
                        {files.map(file => (
                            <Chip
                                key={file.name}
                                label={file.name}
                                onDelete={() => handleRemoveFile(file)}
                                deleteIcon={<Icon icon="mdi:trash-can-outline" color=" #FF0000" />}
                                sx={{ margin: '4px' }}
                            />
                        ))}
                    </Box>
                )}
                {files.length === 0 && (
                    <Typography variant="body1">Drag & drop files here, or click "Upload Files" to select files</Typography>
                )}

            </Box>
            <Box >
                <DialogActions sx={{ paddingBottom: 'unset' }}>
                    <Button onClick={() => onCloce()}>
                        Close
                    </Button>
                    <Button disabled={!files?.length}  onClick={() => onUpload(files)} autoFocus>
                        Upload
                    </Button>
                </DialogActions>
            </Box>
        </>
    );
};


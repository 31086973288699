import axios from "axios";
import jwtDecode from "jwt-decode";
import { getCookie } from "../utils";

export const apiCall = (url, method="get", data={}) => {
    const voAuthToken = getCookie('vo-auth-token')
    const expiryAt = jwtDecode(voAuthToken).exp;
    const agent = getCookie('vo-login-id')

    if (expiryAt < Date.now() / 1000) {
        window.location.href="/";
    }

    return axios({
        method: method,
        url: url,
        data: data,
        headers: {
            user:agent,
            token:voAuthToken
        }
    }).then(({ response }) => {
        return response;
    }).catch((error) => {
        throw new Error(error);
    });
};
import { Snackbar } from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const SnackBarContext = createContext()

const AUTO_DISMISS = 5000

export function SnackBarProvider({ children }) {
  const [alerts, setAlerts] = useState();

  const addAlert = (msg) => setAlerts(msg)

  const value = { addAlert }

  return (
    <SnackBarContext.Provider value={value}>
      {children}
       <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!alerts}
        message={alerts}
        onClose={()=>{setAlerts(null)}}
        key={'top' + 'center'}
        autoHideDuration={AUTO_DISMISS}
        variant="filled"
      />
    </SnackBarContext.Provider>
  )
}

const useSnackBars = () => useContext(SnackBarContext)

export default useSnackBars
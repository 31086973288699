import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { getCookie, removeSSOCookies } from "../utils";
import { API_BASE_URL } from "../config";
import axios from "axios";
import "../homepage.css";

export const Header = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const voAuthToken = getCookie('vo-auth-token')
  const userId = getCookie('vo-userId')
  const loginId = getCookie('vo-login-id')
  const location = useLocation();
  const currentRoute = location.pathname;
  const isDashBoardPage = currentRoute === '/dashboard';

  useEffect(() => {
    if (voAuthToken) {
      const tknData = jwtDecode(voAuthToken);
      if (tknData.exp > Date.now() / 1000) {
        setIsLoggedIn(true);
      } else {
        //Logic here for session expired
        sessionStorage.clear();
        localStorage.clear();
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [voAuthToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentRoute]);

  const dashboardHandler = () => {
    navigate("/dashboard");
  };

  const homeHandler = () => {
    navigate("/company-details");
  };

  const isMobileScreen = window.screen.width < 600


  const logoutHandler = () => {

    axios
      .post(
        API_BASE_URL + '/v1/authentication/user/logout',
        {
          ssoAuth: voAuthToken,
          userId: userId
        },
        {
          headers: {
            Authorization: 'Bearer ' + voAuthToken,
            "Content-Type" : 'application/json',
            loginId:loginId
          }
        }
      ).then(() => {
        sessionStorage.clear();
        localStorage.clear();
        removeSSOCookies()
        sessionStorage.setItem("insureDetails", JSON.stringify({}))
        window.location.replace(`${process.env.REACT_APP_VO_URL}login`);
      })
      .catch((err)=>{
            console.log(err)
      })

  };

  return (
    <header>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-6 col-2 d-flex">
            {isMobileScreen ?
              <Link to={process.env.REACT_APP_VO_URL} style={{ padding: '16px' }}>
                <img
                  src="/images/star.svg"
                  alt="Star Health"
                  width="30"
                  height="30"
                />
              </Link>
              :
              <Link to={process.env.REACT_APP_VO_URL}>
                <img

                  src="/images/star-logo.png"
                  alt="Star Health"
                  className="logo"
                />
              </Link>
            }

          </div>
          <div className="col-sm-6 col-10">
            <div className="d-flex justify-content-end align-items-center">
              {userId && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', margin: '0px 20px' }}>
                <div style={{ borderRadius: '50%', backgroundColor: '#f2f4f7', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <i className="demo-icon icon-account-outline"></i>
                </div>
                <div style={{ fontSize: '12px', fontWeight: '600' }}>{loginId}</div>
              </div>}
              {isLoggedIn &&
                <>
                  {isDashBoardPage ?
                    <img
                      src="/images/home-icon.svg"
                      height="46px"
                      width="46px"
                      alt="Home Icon"
                      title="HOME"
                      className="Rounded10 d-inline-block mr-3"
                      onClick={homeHandler}
                      style={{ cursor: 'pointer', padding: '10px', background: '#f2f4f7', width: 'auto' }}
                    />

                    : <button
                      title="Dashboard"
                      aria-label="Dashboard"
                      className="btn badge BadgeLiteBlue BadgeDashboardIcon Rounded10 d-inline-block mr-3"
                      onClick={dashboardHandler}
                    ></button>}
                </>}
              {isLoggedIn && (
                <button
                  title="Logout"
                  aria-label="Logout"
                  className="btn badge BadgeLiteBlue BadgeLogoutIcon Rounded10 d-inline-block"
                  onClick={logoutHandler}
                ></button>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

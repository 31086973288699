// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 767px) {
  .FormSection {
    min-height: auto;
  }

  .FormButtonSection {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    padding: 10px;
    box-shadow: 2px -5px 12px rgb(0 0 0 / 9%);
    z-index: 1;
  }

  .LeftPageImage img {
    width: 330px;
    margin: 20px auto;
  }

  .MainPage {
    margin-bottom: 80px;
    /* max-height: calc(100vh - 165px);
    overflow-y: auto; */
  }

  .btn {
    padding: 10px;
    font-size: 16px;
  }

  .CustomCard td:first-child {
    width: 100%;
  }

  .CustomCard {
    box-shadow: none;
  }

  .CustomUnStyled p {
    font-size: 14px;
  }

  .sidebar-block .rounded-circle img {
    width: auto;
  }

  .not_found_section img {
    width: 285px;
  }
}

@media (min-width: 576px) {
  .table-sm-borderless,
  .table-sm-borderless td,
  .table-sm-borderless th {
    border: none;
  }
}


@media (max-width: 600px) {
  .react-responsive-modal-modal {
    max-width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/responsive.css"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,SAAS;IACT,QAAQ;IACR,OAAO;IACP,sBAAsB;IACtB,aAAa;IACb,yCAAyC;IACzC,UAAU;EACZ;;EAEA;IACE,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB;uBACmB;EACrB;;EAEA;IACE,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,YAAY;EACd;AACF;;AAEA;EACE;;;IAGE,YAAY;EACd;AACF;;;AAGA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":["@media screen and (max-width: 767px) {\n  .FormSection {\n    min-height: auto;\n  }\n\n  .FormButtonSection {\n    position: fixed;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    background-color: #fff;\n    padding: 10px;\n    box-shadow: 2px -5px 12px rgb(0 0 0 / 9%);\n    z-index: 1;\n  }\n\n  .LeftPageImage img {\n    width: 330px;\n    margin: 20px auto;\n  }\n\n  .MainPage {\n    margin-bottom: 80px;\n    /* max-height: calc(100vh - 165px);\n    overflow-y: auto; */\n  }\n\n  .btn {\n    padding: 10px;\n    font-size: 16px;\n  }\n\n  .CustomCard td:first-child {\n    width: 100%;\n  }\n\n  .CustomCard {\n    box-shadow: none;\n  }\n\n  .CustomUnStyled p {\n    font-size: 14px;\n  }\n\n  .sidebar-block .rounded-circle img {\n    width: auto;\n  }\n\n  .not_found_section img {\n    width: 285px;\n  }\n}\n\n@media (min-width: 576px) {\n  .table-sm-borderless,\n  .table-sm-borderless td,\n  .table-sm-borderless th {\n    border: none;\n  }\n}\n\n\n@media (max-width: 600px) {\n  .react-responsive-modal-modal {\n    max-width: 90%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#36498A'
    }
  },
    typography: {
      fontFamily: 'Manrope, Arial, sans-serif',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontFamily: 'Manrope, Arial, sans-serif',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textDecoration:'none',
            fontWeight:600,
            textTransform:'initial',
          },
          contained: {
          backgroundColor: 'rgb(54, 73, 138)',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: 'rgb(54, 73, 138)',
          },
        },
        outlined: {
          borderColor: 'rgb(54, 73, 138)',
          color: 'rgb(54, 73, 138)',
          '&:hover': {
            borderColor: 'rgb(54, 73, 138)',
            backgroundColor: 'rgba(54, 73, 138, 0.1)',
          },
        },
        link:{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: '48px',
            borderRadius: '8px',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '& .MuiInputBase-input': {
              height: '1rem',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            lineHeight: '1rem',
            fontSize: '14px',
          },
        },
      },
    },
  });
  
  export default theme;
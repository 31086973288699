import React, { useState } from "react";
import { useFormikContext } from "formik";
import { FieldErrorMessage } from "../common/FieldErrorMessage";
import NumberInput from "../common/NumberInput";
import './companyDetails.css'
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack"

const CompanyDetailsForm = ({ showFlag ,setShowFlag}) => {
  const [industry] = useState([
    "IT/BPO",
    "Banking/Finance",
    "Consultants & Advisors",
    "Media/Advertising",
    "Logistics/Transport",
    "Hospital",
    "Food/ Shop & Hospitality",
    "Chemical & Pharma",
    "Education",
    "Govt Sector",
    "Manufacturing",
  ]);

  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext();

  return (
    <main className="row MainPage mx-auto mt-3">
      <Container>
        <div className="FormSection bg-white p-3">
          <div className='info-tooltip'>
            <img
              src="/new-req/information-fill.svg"
              alt='info'
            />
            <div>Only Employer - Employee is selected for others please contact
              technical department for quotation</div>
          </div>
          {showFlag ? (
            <>
              <form action="#">
                <div className='row mx-auto'>
                  <div className="form-group mb-4 col-12 col-sm-6">
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Corporate Name"
                      id="companyName"
                      name="companyName"
                      placeholder="Corporate Name"
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <FieldErrorMessage name="companyName" />
                  </div>
                  <div className="col-12 col-sm-6 mb-4">
                    <TextField
                      variant="outlined"
                      label="Corporate Industry"
                      fullWidth
                      select
                      id="industry"
                      name="industry"
                      value={values.industry}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    >
                      {industry.map((occ, index) => (
                        <MenuItem key={`occ-${index}`} value={occ}>
                          {occ}
                        </MenuItem>
                      ))}
                    </TextField>
                    <FieldErrorMessage name="industry" />
                  </div>
                </div>
                <div className='row mx-auto'>
                  <div className="form-group mb-4 col-12 col-sm-6">
                    <TextField
                      variant="outlined"
                      label="Corporate Address"
                      fullWidth
                      id="address"
                      name="address"
                      placeholder="Corporate Address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <FieldErrorMessage name="address" />
                  </div>
                  <div className="form-group mb-4 col-12 col-sm-6">
                    {/* <label className="FormLabel fs-14">
                      Corporate Pincode <span className="mandatory">*</span>
                    </label> */}
                    <NumberInput
                      name="pincode"
                      placeholder="Corporate Pincode"
                      maxlen="6"
                      className='form-control'
                      currentValue={values.pincode}
                      onChange={(e) => setFieldValue("pincode", e.target.value)}
                      onBlur={handleBlur}
                      required={true}
                    />
                    <FieldErrorMessage name="pincode" />
                  </div>
                </div>
                <div className='row mx-auto'>
                  <div className="form-group mb-4 col-12 col-sm-6">
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Contact Person Name"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <FieldErrorMessage name="name" />
                  </div>
                  <div className="form-group mb-4 col-12 col-sm-6">
                    <TextField
                      label="Contact Person Email ID"
                      variant="outlined"
                      fullWidth
                      id="emailId"
                      name="emailId"
                      placeholder="Email ID"
                      value={values.emailId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <FieldErrorMessage name="emailId" />
                  </div>
                </div>
                <div className="form-group mb-4 col-12 col-sm-6">
                  {/* <label className="FormLabel fs-14">
                    Contact Person Phone no<span className="mandatory">*</span>
                  </label> */}
                  <NumberInput
                    name="phoneNo"
                    placeholder="Contact Person Phone no"
                    maxlen="10"
                    className="form-control"
                    currentValue={values.phoneNo}
                    onChange={(e) => setFieldValue("phoneNo", e.target.value)}
                    onBlur={handleBlur}
                    required={true}
                  />
                  <FieldErrorMessage name="phoneNo" />
                </div>
              </form>
            </>
          ) : (
            <Stack alignItems={'center'}>
            <h5 className="fs-16 text-center">
              Kindly contact to corporate office technical team.
            </h5>
            <Button variant="contained" onClick={()=>setShowFlag(true)}>Back</Button>
            </Stack>
          )}
        </div>
        <div className="warningBox">
          <Typography padding={'6px'} fontSize={'14px'} fontWeight={600} color={'#ff0000'}>
            The quote will be given with the condition that all employees and
            dependents are covered without any selection
          </Typography>
        </div>
      </Container>
    </main>
  );
};

export default CompanyDetailsForm;

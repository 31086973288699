import React, { useState ,useEffect} from "react";
import LeadForm from "../lead-form";
import Stepper  from "../common/Stepper/Stepper";
import Box from "@mui/material/Box"
import { useNavigate } from "react-router-dom";

const LeadPrefillWrapper = ({sessionHandler}) => {
  const navigate = useNavigate();
  const insureDetails = JSON.parse(sessionStorage.getItem("insureDetails"))
  const companyDetails = JSON.parse(sessionStorage.getItem("companyDetails"))
  const [initialValues, setInitialValues] = useState({
    sumInsured: ['2 Lakhs'],
    familyDetails: {},
    policyType:'fresh',
    planType:'individual',
    companyId:companyDetails ?companyDetails.companyId:'',
    marketRenewal:{},
    marketRenewalDoc:{},
    agreeToTerms:false
  });

  useEffect(()=>{
    if(!(companyDetails?.companyId)){
      navigate('/company-details')}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    if(insureDetails && insureDetails.leadDetails){
       setInitialValues({
        ...insureDetails.leadDetails,
        companyId:companyDetails ?companyDetails.companyId:'',
        agreeToTerms:false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

    return <Box data-testid="lead-form" marginBottom={'50px'}><Stepper/><LeadForm sessionHandler={sessionHandler} initialValues={initialValues} /></Box>;
};

export default LeadPrefillWrapper;

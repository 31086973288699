import React, { Fragment } from "react";
import { FieldArray } from "formik";
import NumberInput from "../common/NumberInput";
import { FieldErrorMessage } from "../common/FieldErrorMessage";
import Support from "../support";

const UserDetails = ({ prevStep, values }) => {
  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };
  const supportData = {
    title: "Why choose Star Health?",
    desc: "",
    cta: "",
  };

  const networkSupportData = {
    title: "",
    descTitle: "Largest Hospital Network: ",
    desc: "Over 14,000+ network hospitals across India",
    cta: "",
  };

  const wellnessSupportData = {
    title: "",
    descTitle: "Wellness Programs: ",
    desc: "Tailor made wellness programs that help improve the overall health",
    cta: "",
  };

  const talkSupportData = {
    title: "",
    descTitle: "Talk to Star: ",
    desc: "A one-of-its-kind service where our customers can avail doctor consultation on call at free of cost",
    cta: "",
  };

  return (
    <Fragment>
      <main className="row mx-auto mt-3 container LeadPage">
        <div className="col-sm-4 order-2 order-sm-1">
          <Support support={supportData} imgName="star.svg" />
          <Support support={networkSupportData} imgName="hospital.svg" />
          <Support support={wellnessSupportData} imgName="wellness-icon.svg" />
          {/* Need to Update the following */}
          {/* <Support support={talkSupportData} imgName="user-icon.png" />  */}
        </div>
        <div className="col-sm-8 order-1 order-sm-2">
          <FieldArray
            name="familyDetails"
            render={(arrayHelpers) =>
              values.sumInsured.map((data, index) => (
                <div key={index} className="FormSection bg-white p-4 mb-4">
                  <h5 className="fs-18 col-11 px-0">
                    Please provide us with the following information for the
                    selected Sum Insured of &nbsp;
                    <strong className="text-blue f700">{data} </strong>
                  </h5>
                  <p className="fs-14 mb-1">
                    Provide the number of users segregated based on their age
                  </p>
                  <h5 className="text-danger fs-16">
                    The quote will be given with the condition that all
                    employees and dependents are covered without any selection
                  </h5>
                  <div className="FormTable p-0 p-sm-4">
                    <table className="text-center table table-bordered table-sm-borderless CustomCard bg-white p-0 p-sm-2 rounded">
                      <tbody>
                        <tr>
                          <td className="px-3 d-flex d-sm-table-cell">
                            <img src="/images/employee.svg" alt="" />
                            <label className="d-block f500 mt-2 mt-sm-1 ml-3 ml-sm-0">
                              Employee
                            </label>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              18-35
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="emp18"
                                name={`familyDetails['${data}'].emp18`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].emp18`}
                              />
                            </div>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              36-45
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="emp36"
                                name={`familyDetails['${data}'].emp36`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].emp36`}
                              />
                            </div>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              46-55
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="emp46"
                                name={`familyDetails['${data}'].emp46`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].emp46`}
                              />
                            </div>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              56-65
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="emp56"
                                name={`familyDetails['${data}'].emp56`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].emp56`}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="text-center table table-bordered table-sm-borderless CustomCard bg-white p-0 p-sm-2 rounded mt-3">
                      <tbody>
                        <tr>
                          <td className="px-3 d-flex d-sm-table-cell align-middle">
                            <img src="/images/spouse.svg" alt="" />
                            <label className="d-block f500 mt-2 mt-sm-1 ml-3 ml-sm-0">
                              Spouse
                            </label>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell align-middle">
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="spouse18"
                                name={`familyDetails['${data}'].spouse18`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].spouse18`}
                              />
                            </div>
                          </td>
                          {/* <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              36-45
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="spouse36"
                                name={`familyDetails['${data}'].spouse36`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].spouse36`}
                              />
                            </div>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              46-55
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="spouse46"
                                name={`familyDetails['${data}'].spouse46`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].spouse46`}
                              />
                            </div>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              56-65
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="spouse56"
                                name={`familyDetails['${data}'].spouse56`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].spouse56`}
                              />
                            </div>
                          </td> */}
                        </tr>
                      </tbody>
                    </table>

                    <table className="text-center table table-bordered table-sm-borderless CustomCard bg-white p-0 p-sm-2 rounded mt-3">
                      <tbody>
                        <tr>
                          <td className="px-3 d-flex d-sm-table-cell align-middle">
                            <img src="/images/parents.svg" alt="" />
                            <label className="d-block f500 mt-2 mt-sm-1 ml-3 ml-sm-0">
                              Parents
                            </label>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell align-middle">
                            {/* <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              18-35
                            </b> */}
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="parents18"
                                name={`familyDetails['${data}'].parents18`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].parents18`}
                              />
                            </div>
                          </td>
                          {/* <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              36-45
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="parents36"
                                name={`familyDetails['${data}'].parents36`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].parents36`}
                              />
                            </div>
                          </td> */}
                          {/* <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              46-55
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="parents46"
                                name={`familyDetails['${data}'].parents46`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].parents46`}
                              />
                            </div>
                          </td> */}
                          {/* <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              56-90
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="parents56"
                                name={`familyDetails['${data}'].parents56`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].parents56`}
                              />
                            </div>
                          </td> */}
                        </tr>
                      </tbody>
                    </table>

                    <table className="text-center table table-bordered table-sm-borderless CustomCard bg-white p-0 p-sm-2 rounded mt-3">
                      <tbody>
                        <tr>
                          <td className="px-3 d-flex d-sm-table-cell align-middle">
                            <img src="/images/inlaws.svg" alt="" />
                            <label className="d-block f500 mt-2 mt-sm-1 ml-3 ml-sm-0">
                              In-Laws
                            </label>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell align-middle">
                            {/* <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              18-35
                            </b> */}
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="inlaws18"
                                name={`familyDetails['${data}'].inlaws18`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].inlaws18`}
                              />
                            </div>
                          </td>
                          {/* <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              36-45
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="inlaws36"
                                name={`familyDetails['${data}'].inlaws36`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].inlaws36`}
                              />
                            </div>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              46-55
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="inlaws46"
                                name={`familyDetails['${data}'].inlaws46`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].inlaws46`}
                              />
                            </div>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell">
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">
                              56-90
                            </b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="inlaws56"
                                name={`familyDetails['${data}'].inlaws56`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].inlaws56`}
                              />
                            </div>
                          </td> */}
                        </tr>
                      </tbody>
                    </table>

                    <table className="text-center table table-bordered table-sm-borderless CustomCard bg-white p-0 p-sm-2 rounded mt-3 mb-0">
                      <tbody>
                        <tr>
                          <td className="px-3 d-flex d-sm-table-cell">
                            <img src="/images/child.svg" alt="" />
                            <label className="d-block f500 mt-2 mt-sm-1 ml-3 ml-sm-0 text-nowrap">
                              Children below 25
                            </label>
                          </td>
                          <td className="px-3 d-flex d-sm-table-cell align-middle">
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id="child"
                                name={`familyDetails['${data}'].child`}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage
                                name={`familyDetails['${data}'].child`}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))
            }
          ></FieldArray>
          <FieldErrorMessage name={`familyDetails`} />

          <div className="FormButtonSection d-flex justify-content-between align-items-center mt-4 w-100">
            {/* <Link className="btn btn-back bg-white" to="/sum-insured">
              Go Back
            </Link>
            <Link className="btn btn-next" to="/quote">
              Get Started
            </Link>
            <Outlet /> */}
            <button
              className="btn btn-back bg-white"
              onClick={Previous}
              type="button"
            >
              Go Back
            </button>
            <button className="btn btn-next" type="submit">
              Continue
            </button>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default UserDetails;

import jwtDecode from 'jwt-decode';
import { getCookie } from "./utils"
import { useEffect } from 'react';

const ProtectedRoute = ({ children ,sessionHandler}) => {
    const voAuthToken = getCookie('vo-auth-token')
    const urlParams = new URLSearchParams(window.location.search);
    const authTokenValue = urlParams.get('token');

    useEffect(()=>{
        if(authTokenValue)
            return 
        if (!voAuthToken) {
            return sessionHandler();
        }
        const expiryAt = jwtDecode(voAuthToken).exp;
    
        if (expiryAt < (Date.now() / 1000)) {
             return sessionHandler();
        }
    },[voAuthToken])

    return (children);
};

export default ProtectedRoute;
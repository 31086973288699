import React, { Fragment ,useState} from "react";
import { Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import ProtectedRoute from "./protectedRoute";
import Quote from "./components/quote";
import CompanyDetails from "./components/company-details";
import Dashboard from "./components/dashboard";
import LeadPrefillWrapper from "./components/lead-prefill-wrapper";
import Notfound from "./components/notfound";
import AuthGuard from "./AuthGuard";
import SessionExpired from "./components/modals/SessionExpired";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { SnackBarProvider } from "./components/common/snackbar/SmeSnackbar";

const App = () => {

  const [isSessionExpired, setIsSessionExpired] = useState(false)

  const handleSession = (value) => {
    setIsSessionExpired(value ? value: true)
  }

  return (
    <SnackBarProvider>
    
    <AuthGuard sessionHandler = {handleSession}>
    <Fragment>
      <link rel="stylesheet" href="/css/fontello.css"></link>
      <div className="WebLoader d-none">
        <div className="LoaderImg"></div>
      </div>
      <div className="MainPageSection">
        <Header />
        <div className="MainSection">
          <Typography textAlign={'center'} padding={'12px'} variant="h6" fontWeight={700}>GMC Quick Quote</Typography>
          <Divider variant="middle"/>
          <Routes>
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute sessionHandler = {handleSession}>
                    <Dashboard sessionHandler = {handleSession}/>
                  </ProtectedRoute>
                }
              />
              <Route
                index
                path="/company-details"
                element={
                  <ProtectedRoute sessionHandler = {handleSession}>
                    <CompanyDetails sessionHandler = {handleSession}/>
                  </ProtectedRoute>
                }
              />
              <Route
                path="lead"
                element={
                  <ProtectedRoute sessionHandler = {handleSession}>
                      <LeadPrefillWrapper sessionHandler = {handleSession}/>
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<ProtectedRoute sessionHandler = {handleSession}><Notfound /></ProtectedRoute>} />
            <Route
              path="quote"
              element={
                <ProtectedRoute sessionHandler = {handleSession}>
                  <Quote sessionHandler = {handleSession}/>
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
        {isSessionExpired && <SessionExpired/>}
      </div>
    </Fragment>
    </AuthGuard>
    </SnackBarProvider>
  );
};

export default App;

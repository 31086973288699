import React from "react";
import { Pagination } from '@mui/material'

type SmePaginateTypes = {
    totalPages: number,
    page: number,
    onPageChange: (page: number) => void
}

const SmePaginate = ({onPageChange,page,totalPages }: SmePaginateTypes) => {
    return <>
        <Pagination defaultValue={page} count={totalPages} page={page} color="primary" onChange={(e,n)=>{onPageChange(n)}}  />
    </>
}

export default SmePaginate;
import Cookies from "universal-cookie";

export const Webloader = (status) => {
  if (status === "show") {
    document.querySelector(".WebLoader")?.classList?.remove("d-none");
  } else {
    document.querySelector(".WebLoader")?.classList?.add("d-none");
  }
};

export const getURLQueryString = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

export const ucFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function getCookie(cname) {
  let name = cname + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const getDecodedCookie = (name) => {
  const encodedValue = getCookie(name);
  if (encodedValue) {
    return decodeURIComponent(encodedValue);
  }
  return null;
};

export const estimateSizeInBytes = (data) => {
  // Convert data to a JSON string
  const jsonString = JSON.stringify(data);
  // Use Blob to estimate size
  return new Blob([jsonString]).size;
};

export const removeSSOCookies = function() {
  const ssoCookies = ['vo-auth-token','vo-login-id','vo-ssoToken']
  const cookies = new Cookies();
  ssoCookies.map((eachCookieName) => cookies.remove(eachCookieName, { path: '/' ,domain: ".starhealth.in"}));
};

export function setCookie(key, value, options) {
  const cookies = new Cookies();
  cookies.set(key, value, options);
}

export function removeEmptyValues(obj) {
  for (let key in obj) {
      if (typeof obj[key] === 'object') {
          obj[key] = removeEmptyValues(obj[key]);
          if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
          }
      } else if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
          // Remove key-value pair if the value is null, undefined, or an empty string
          delete obj[key];
      }
  }
  return obj;
}

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export function maskEmail(email) {
  const [localPart, domain] = email.split("@");
  const unmaskedLength = localPart.length > 1 ? 2 : 1;
  const maskedPart = "x".repeat(localPart.length - unmaskedLength);
  const maskedEmail = `${localPart.slice(0, unmaskedLength)}${maskedPart}@${domain}`;
  
  return maskedEmail;
}

import React, { useEffect, useState } from "react";
import CompantDetailsForm from "./company-details-form";
import "react-responsive-modal/styles.css";
import Stepper from "../common/Stepper/Stepper";
import SubmitFooter from "../common/Footer/SubmitFooter";
import { FormikProvider,useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { getCookie, Webloader } from "../../utils";
import { useNavigate } from "react-router-dom";
import useSnackBars from "../common/snackbar/SmeSnackbar";

const CompanyDetails = ({ sessionHandler }) => {
  const voAuthToken = getCookie('vo-auth-token')
  const [showFlag, setShowFlag] = useState(true);
  const { addAlert } = useSnackBars() || {};
  const agent = getCookie('vo-login-id')
  const navigate = useNavigate();
  const [userType] = useState(
    sessionStorage.getItem("userType")
      ? sessionStorage.getItem("userType")
      : false
  );
  const insureDetails = JSON.parse(sessionStorage.getItem("insureDetails"))
  const [initialValues, setInitialValues] = useState({
    name: "",
    emailId: "",
    companyName: "",
    phoneNo: "",
    address: "",
    pincode: "",
    industry: "",
  });

  useEffect(() => {
    if (insureDetails && insureDetails.companyDetails) {
      setInitialValues(insureDetails.companyDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      saveCompanyDetails(values);
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
      .required("Provide Contact Person name")
      .matches(/^\S+.*\S+$/, 'Name must not have extra spaces before or after')
      .test(
        "len",
        "Contact Person Name should not exceed 50 characters",
        (val) => val && val.toString().length <= 50)
      .test('alpha characters',"Provide valid name.", (val) => /^[a-zA-Z\s]+$/.test(val))  
      ,
      industry: Yup.string().required("Provide Industry"),
      emailId: Yup.string()
        .email("Invalid email address")
        .required("Provide work email")
        .test('email',"Provide valid email.", (val) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val))
        ,
      phoneNo: Yup.string()
        .required("Provide Phone Number")
        .test(
          "len",
          "Provide Valid Phone Number",
          (val) => val && val.toString().length === 10
        )
        .test('alpha characters',"Provide Valid Phone Number", (val) => /^[56789]\d{9}$/.test(val))
        ,  
      companyName: Yup.string()
      .required("Provide company name")
      .matches(/^\S+.*\S+$/, 'Name must not have extra spaces before or after')
      .test('special characters',"Provide Valid Company Name", (val) => /^(?!\s)[A-Za-z0-9\s]+(?<!\s)$/.test(val))
      ,
      address: 
      Yup.string().required("Provide Address")
      .test(
        "len",
        "Address length should not to be less than 10 and greater than 100",
        (val) => val && val.toString().length >= 10 && val.toString().length <= 100
      )
      .matches(/^\S+.*\S+$/, 'Address must not have extra spaces before or after')
      .test('special characters',"Provide Valid Company Address", (val) => /^(?!\s)[a-zA-Z0-9,.\-/#()'"\s\\]{10,100}(?<!\s)$/.test(val))
      ,
      pincode: Yup.number()
        .required("Provide pincode")
        .test(
          "len",
          "Must be 6 digits long",
          (val) => val && val.toString().length === 6
        ),
    }),
  });


  const saveCompanyDetails = (values) => {
    if (userType === "partners") {
      sessionStorage.setItem("brokerPincode", values.pincode);
    }
    Webloader("show")
    // const accessToken = localStorage.getItem("access_token");
    // const userId = jwtDecode(accessToken).sub;
    axios
      .post(`${API_BASE_URL}/sme-api/user/company-details`, values, {
        headers: {
          user:agent,
          token:voAuthToken
        },
      })
      .then((response) => {
        if (
          ["Media/Advertising", "Hospital", "Govt Sector"].includes(
            values.industry
          )
        ) {
          setShowFlag(false);
        } else {
          if(response.data)
          sessionStorage.setItem("companyDetails", JSON.stringify(response.data.data))
          handleCompanyDetails(values)
          navigate("/lead");
        }
        Webloader()
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.error('Bad Request:', error.response.data);
          sessionHandler()
        }
        else
        addAlert("Something Went Wrong !!!");
        Webloader()
      });
  };

  const handleCompanyDetails = (values) =>{
    const updatedInsureDetails = { ...insureDetails,companyDetails:values}
    sessionStorage.setItem("insureDetails",JSON.stringify(updatedInsureDetails));
  }


  return (
    <div>
      <Stepper />
      <FormikProvider value={formik}>
        {initialValues && <CompantDetailsForm setShowFlag={setShowFlag} showFlag={showFlag} initialValues={initialValues} sessionHandler={sessionHandler} />}
        {showFlag && <SubmitFooter showBack={false} handleSubmit={formik.handleSubmit}/>}
      </FormikProvider>
    </div>
  );
};

export default CompanyDetails;

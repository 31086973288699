// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sessionModal{
    max-width: 358px;
  }

.closeIconModal{
  outline: none;
}  

.benefitsModal {
  width: 80%;
  max-height: 70vh;
  padding-top: 0px;
}

.benefitsModal::-webkit-scrollbar {
  width: 1px;
}

.benefitsModal::-webkit-scrollbar-thumb {
  background:transparent;
}

.benefitsModal::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.benefitsModal::-webkit-scrollbar-track {
  background: transparent;
}

`, "",{"version":3,"sources":["webpack://./src/components/modals/modals.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;AAEF;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".sessionModal{\n    max-width: 358px;\n  }\n\n.closeIconModal{\n  outline: none;\n}  \n\n.benefitsModal {\n  width: 80%;\n  max-height: 70vh;\n  padding-top: 0px;\n}\n\n.benefitsModal::-webkit-scrollbar {\n  width: 1px;\n}\n\n.benefitsModal::-webkit-scrollbar-thumb {\n  background:transparent;\n}\n\n.benefitsModal::-webkit-scrollbar-thumb:hover {\n  background: transparent;\n}\n\n.benefitsModal::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
const Notfound = () => {
    return (
        <div className="not_found_section text-center mt-3 position-relative">
            <div className="text_404">404</div>
            <img src="/images/not-found-bg.svg" className="m-auto" alt='not-found'/>
            <h4 className="my-3">Page Not Found</h4>
            <p className="mb-0">The page you are trying to access doesn&#x275C;t exist or has been moved.</p>
            <p>
                Try going back to our homepage.
            </p>
            <a className="btn btn-next" href="/company-details">Go to homepage</a>
        </div>
    );
};

export default Notfound;

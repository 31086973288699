import axios from 'axios';
import { UploadMarketRenewalDocRequest } from './LeadCard';

export const toBase64 = (file:File) => new Promise((resolve:(val:string)=>void, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
});

export class LeadApiService{
     static baseUrl=process.env.REACT_APP_API_BASE_URL;
     

    static getDocumentList=(leadID:string|number, token:string, agent:string)=>{
        const url=`${LeadApiService.baseUrl}/sme-api/lead/${leadID}/document`
        return axios.get(url, {
        headers: {
          user: agent,
          token: token,
        },
      })
    }

    static uploadCategoryDocument=(req:UploadMarketRenewalDocRequest, leadId:string|number,cmpanyId:string, token:string, agent:string)=>{
        const apiReq={
            "companyId":cmpanyId,
            "marketRenewalDoc":{
                [`${req.category}`]:{
                "displayName":`${req.category}_${cmpanyId}.${req.documentType}`,
                "documentType":req.documentType,
                "documentImage":req.documentImage
                }
            }
        }

        const url=`${LeadApiService.baseUrl}/sme-api/lead/update/${leadId}`
        return axios.post(url, 
            apiReq,
            {
            headers: {
              user: agent,
              token: token,
            },
      })
    }

    static deleteCategoryDocument=(category:string, leadId:string|number,documentId:string, token:string, agent:string)=>{
        const url=`${LeadApiService.baseUrl}/sme-api/lead/${leadId}/document/${documentId}`
        return axios.post(url,{}, 
            {
            headers: {
              user: agent,
              token: token,
            },
            params:{
                docType:category
            }
      })
    }
}
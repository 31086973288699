import React, { Fragment } from "react";
import { useFormikContext } from "formik";
import { FieldErrorMessage } from "../common/FieldErrorMessage";
import NumberInput from "../common/NumberInput";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SubmitFooter from "../common/Footer/SubmitFooter";
import Radio from "@mui/material/Radio"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import RadioGroup from "@mui/material/RadioGroup"
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import IconButton from '@mui/material/IconButton';
import { Icon } from "@iconify/react/dist/iconify.js";
import Grid from "@mui/material/Grid"
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { styled } from '@mui/material/styles';
import { convertToBase64 } from "./../../utils"
import useSnackBars from "../common/snackbar/SmeSnackbar";

const InsuranceDetails = ({ nextStep }) => {
  const { values, setFieldValue, handleChange, handleBlur ,setTouched} = useFormikContext();
  const companyDetails = JSON.parse(sessionStorage.getItem("companyDetails"))
  const { addAlert } = useSnackBars()||{}
  const sumInsuredArr = ['2 Lakhs', '3 Lakhs', '4 Lakhs', '5 Lakhs']
  const floaterFamilyDetails = [
    { displayValue: 'Spouse', image: 'spouse.svg', key: 'spouse' },
    { displayValue: 'Parents', image: 'parents.svg', key: 'parents' },
    { displayValue: 'In-Laws', image: 'inlaws.svg', key: 'inlaws' },
    { displayValue: 'Children below 25', image: 'child.svg', key: 'child' }
  ]
  const empFloater = [
    {
      key: 'emp18',
      displayValue: '18-35 yrs',
    },
    {
      key: 'emp',
      displayValue: '36-65 yrs',
    },
  ]

  const handleAddSumIns = () => {
    let i = 0
    while (i < sumInsuredArr.length) {
      if (!values.sumInsured.includes(sumInsuredArr[i])) {
        setFieldValue('sumInsured', [...values.sumInsured, sumInsuredArr[i]])
        break;
      }
      i++
    }
  }

  const handleDelete = (index) => {
    const updatedFamilyDetails = { ...values.familyDetails }
    const updatedSumInsured = [...values.sumInsured]
    delete updatedFamilyDetails[values.sumInsured[index]]
    updatedSumInsured.splice(index, 1)
    setFieldValue('familyDetails', updatedFamilyDetails)
    setFieldValue('sumInsured', updatedSumInsured)
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleDeleteFile = (fileTypeSelected) => {
    setFieldValue(`marketRenewalDoc.${fileTypeSelected}`,null)
  } 

  const handleFileChange = async (e, fileType) => {


    const selectedFile = e.target.files[0]

    // Check if a file was selected
    if (!selectedFile) {
      return; // Exit if no file was selected
    }
  
    // Check file size (7 MB = 7 * 1024 * 1024 bytes)
    const maxSize = 7 * 1024 * 1024;
    if (selectedFile.size > maxSize) {
      addAlert('File size exceeds the 7 MB limit.');
      return; // Exit if the file is too large
    }
    const base64 = await convertToBase64(selectedFile);
    const base64WithoutPrefix = base64.split(',')[1];
    if (selectedFile) {
      const fileData = {
        documentImage: base64WithoutPrefix,
        documentType: selectedFile.name.split('.').pop().toLowerCase(),
        displayName: `${companyDetails.companyId}_${[fileType]}.${selectedFile.name.split('.').pop().toLowerCase()}`,
      }
      setFieldValue(`marketRenewalDoc.${[fileType]}`, fileData)
    }
  }

  const getPolicyPeriodToValue = (selectedDate) => {
 // Parse the date string to create a Date object
 let date = new Date(selectedDate);

 // Add one year
 date.setFullYear(date.getFullYear() + 1);

 date.setDate(date.getDate() - 1);

 // Format the date as yyyy-mm-dd
 let year = date.getFullYear();
 let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
 let day = String(date.getDate()).padStart(2, '0');
   setFieldValue('marketRenewal.policyPeriodTo',`${year}-${month}-${day}`)
  }

  const handleDisabled = () => {
      return !(values.marketRenewal.policyPeriodFrom)
  }

  const resetFamilyDetails = (indexUpdated) => {
    const updatedFamilyDetails = { ...values.familyDetails }
    if(updatedFamilyDetails[values.sumInsured[indexUpdated]]){
     delete updatedFamilyDetails[values.sumInsured[indexUpdated]]
     setFieldValue('familyDetails', updatedFamilyDetails)
    }
  }

  return (
    <Fragment>
      <Stack className="MainPage mx-auto">
        <Box className="FormSection bg-white p-4 m-4">
          <Typography fontSize={'16px'} fontWeight={700}>
            Select the Sum Insured amount you want to cover each family for
          </Typography>

          <FormControl sx={{ padding: '12px 0px' }}>
            <Typography fontSize={'14px'} fontWeight={500} padding={'8px 0px'}>
              Plan type <span>*</span>
            </Typography>
            <RadioGroup
              aria-label="planType"
              name="planType"
              value={values.planType}
              onChange={(event) => {
                setFieldValue('sumInsured', ['2 Lakhs'])
                setFieldValue('familyDetails',{})
                setFieldValue('planType', event.target.value)
                setTouched({}, false);
                return 
              }
            }
              className="input-group mb-2"
            >
              <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={'16px'}>
                <Box borderRadius={'8px'} border={'1px solid'} borderColor={values.planType === 'individual' ? '#36498A' : '#E6E6E6'} >
                  <FormControlLabel
                    sx={{ margin: '0px' }}
                    value="individual"
                    control={<Radio sx={{ padding: '4px' }} size="small" />}
                    label={<Typography fontSize={'14px'} padding={'0px 5px'} fontWeight={'500'}>Individual</Typography>}
                  />
                </Box>
                <Box borderRadius={'8px'} border={'1px solid'} borderColor={values.planType === 'floater' ? '#36498A' : '#E6E6E6'}>
                  <FormControlLabel
                    sx={{ margin: '0px' }}
                    value="floater"
                    control={<Radio sx={{ padding: '4px' }} size="small" />}
                    label={<Typography fontSize={'14px'} padding={'0px 5px'} fontWeight={'500'}>Floater</Typography>}
                  />
                </Box>
              </Stack>
            </RadioGroup>
          </FormControl>

          {values && values.sumInsured && values.sumInsured.map((_sumDetails, mainIndex) => {
            return <Box key={mainIndex}>
              <Divider sx={{ borderColor: '#E6E6E6' }} />
              <div className='sum-insured-section'>
                <Stack marginBottom={'4px'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <div className="col-12 col-sm-6 p-0" >
                    <TextField
                      variant="outlined"
                      label="Select Sum Insured "
                      fullWidth
                      select
                      name={`sumInsured[${[mainIndex]}]`}
                      id={`sumInsured[${[mainIndex]}]`}
                      value={values.sumInsured[mainIndex]}
                      onChange={(e)=>{
                        resetFamilyDetails(mainIndex)
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      required
                      sx={{ background: '#ffffff', borderRadius: '8px' }}
                    >
                      {sumInsuredArr
                        .filter((each) => {
                          const selectedVales = [...values.sumInsured.slice(0, mainIndex), ...values.sumInsured.slice(mainIndex + 1)]
                          return !selectedVales.includes(each)
                        })
                        .map((occ, index) => (
                          <MenuItem key={`occ-${index}`} value={occ}>
                            ₹ {occ}
                          </MenuItem>
                        ))}
                    </TextField>
                    <FieldErrorMessage name="sumInsured" />
                  </div>
                  {mainIndex !== 0 && <IconButton aria-label="delete" onClick={() => handleDelete(mainIndex)}>
                    <Icon icon="mdi:delete" width="20px" height="20px" style={{ color: '#FF0000' }} alt='Delete' />
                  </IconButton>}
                </Stack>
                <Typography padding={'10px 0px'} fontSize={'15px'} fontWeight={600}>Provide the number of employees segregated based on their age*</Typography>
                {values.planType === 'individual' && <table className="text-center table table-bordered table-sm-borderless CustomCard bg-white p-0 p-sm-2 rounded">
                  <tbody>
                    <tr>
                      <td className="px-3 d-flex d-sm-table-cell">
                        <img src="/images/employee.svg" alt="" />
                        <label className="d-block f500 mt-2 mt-sm-1 ml-3 ml-sm-0">
                          Employee
                        </label>
                      </td>
                      {['18-35', '36-45', '46-55', '56-65'].map((ageRange, index) => (
                        <td className="px-3 d-flex d-sm-table-cell" key={index}>
                          <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">{ageRange} yrs</b>
                          <div>
                            <NumberInput
                              currentValue={(values.familyDetails && values.familyDetails[`${values.sumInsured[mainIndex]}`] && values.familyDetails[`${values.sumInsured[mainIndex]}`][`emp${ageRange.slice(0, 2)}`])||""}
                              type="text"
                              aria-invalid="true"
                              required
                              aria-required="true"
                              aria-disabled="false"
                              aria-describedby=""
                              id={`emp${ageRange}`}
                              name={`familyDetails['${values.sumInsured[mainIndex]}'].emp${ageRange.slice(0, 2)}`}
                              placeholder=""
                              className="form-control"
                            />
                            <FieldErrorMessage name={`familyDetails['${values.sumInsured[mainIndex]}'].emp${ageRange}`} />
                          </div>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>}

                {values.planType === 'floater' && <><table className="text-center table table-bordered table-sm-borderless CustomCard bg-white p-0 p-sm-2 rounded">
                  <tbody>
                    <tr>
                      <td className="px-3 d-flex d-sm-table-cell">
                        <img src="/images/employee.svg" alt="" />
                        <label className="d-block f500 mt-2 mt-sm-1 ml-3 ml-sm-0">
                          Employee
                        </label>
                      </td>
                      {empFloater.map((each, index) => (
                        <td className="px-3 d-flex d-sm-table-cell" key={index}>
                          <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">{each.displayValue}</b>
                          <div>
                            <NumberInput
                              type="text"
                              aria-invalid="true"
                              required
                              aria-required="true"
                              aria-disabled="false"
                              aria-describedby=""
                              id={each.key}
                              name={`familyDetails['${values.sumInsured[mainIndex]}'].${each.key}`}
                              currentValue={(values.familyDetails && values.familyDetails[`${values.sumInsured[mainIndex]}`]&& values.familyDetails[`${values.sumInsured[mainIndex]}`][each.key])||""}
                              placeholder=""
                              className="form-control"
                            />
                            <FieldErrorMessage name={`familyDetails['${values.sumInsured[mainIndex]}'].${each.key}`} />
                          </div>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>

                  <table className="text-center table table-bordered table-sm-borderless CustomCard bg-white p-0 p-sm-2 rounded">
                    <tbody>
                      <tr>
                        {floaterFamilyDetails.map((detail, index) => (
                          <td className="px-3 d-flex d-sm-table-cell" key={index}>
                            <img src={`/images/${detail.image}`} alt={detail.key}></img>
                            <b className="mb-2 fs-14 d-block mr-2 mr-sm-0 mt-2 mt-sm-0 col-4 col-sm-12">{detail.displayValue}</b>
                            <div>
                              <NumberInput
                                type="text"
                                aria-invalid="true"
                                required
                                aria-required="true"
                                aria-disabled="false"
                                aria-describedby=""
                                id={`familyDetails['${values.sumInsured[mainIndex]}'].${detail.key}`}
                                name={`familyDetails['${values.sumInsured[mainIndex]}'].${detail.key}`}
                                currentValue={(values.familyDetails && values.familyDetails[`${values.sumInsured[mainIndex]}`]&& values.familyDetails[`${values.sumInsured[mainIndex]}`][detail.key])||""}
                                placeholder=""
                                className="form-control"
                              />
                              <FieldErrorMessage name={`familyDetails['${values.sumInsured[mainIndex]}'].${detail.key}`} />
                            </div>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </>
                }

              </div>
            </Box>
          })}
          <FieldErrorMessage name={`familyDetails`} />
          {values.sumInsured && values.sumInsured.length !== sumInsuredArr.length && <Stack alignItems={'center'} justifyContent={'center'} boxShadow={'none'} padding={'12px 0px'}>
            <Button sx={{ border: '1px dashed #3194FD', color: '#3194FD', borderRadius: '8px' }} onClick={() => handleAddSumIns()}>
              + Add Sum Insured
            </Button>
          </Stack>}
          <Divider sx={{ borderColor: '#E6E6E6' }} />
          <FormControl sx={{ padding: '12px 0px' }}>
            <Typography fontSize={'14px'} fontWeight={500} padding={'8px 0px'}>
              Policy type <span>*</span>
            </Typography>
            <RadioGroup
              aria-label="policyType"
              name="policyType"
              value={values.policyType}
              onChange={(event) => setFieldValue('policyType', event.target.value)}
              className="input-group mb-2"
            >
              <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={'16px'}>
                <Box borderRadius={'8px'} border={'1px solid'} borderColor={values.policyType === 'fresh' ? '#36498A' : '#E6E6E6'}>
                  <FormControlLabel
                    value="fresh"
                    sx={{ margin: '0px' }}
                    control={<Radio sx={{ padding: '4px' }} size="small" />}
                    label={<Typography fontSize={'14px'} padding={'0px 5px'} fontWeight={'500'}>Fresh</Typography>}
                  />
                </Box>
                <Box borderRadius={'8px'} border={'1px solid'} borderColor={values.policyType === 'market_renewal' ? '#36498A' : '#E6E6E6'}>
                  <FormControlLabel
                    value="market_renewal"
                    sx={{ margin: '0px' }}
                    control={<Radio sx={{ padding: '4px' }} size="small" />}
                    label={<Typography fontSize={'14px'} padding={'0px 5px'} fontWeight={'500'}>Market Renewal</Typography>}
                  />
                </Box>
              </Stack>
            </RadioGroup>
          </FormControl>
          {values.policyType === 'market_renewal' && <>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                <Typography fontWeight={500} className="fs-15 mt-3">Previous Policy Details</Typography>
              </Grid>

              {/* Form fields */}
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Name of Existing Insurer"
                      variant="outlined"
                      placeholder="Existing Insurer"
                      name="marketRenewal.insurerName"
                      value={values.marketRenewal.insurerName}
                      onChange={handleChange}
                    />
                    <FieldErrorMessage name="marketRenewal.insurerName"/>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Expiring Policy Number"
                      variant="outlined"
                      placeholder="Policy Number"
                      name="marketRenewal.expiringPolicyNo"
                      value={values.marketRenewal.expiringPolicyNo}
                      required={true}
                      onChange={handleChange}
                    />
                    <FieldErrorMessage name={`marketRenewal.expiringPolicyNo`} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Name of Existing TPA"
                      variant="outlined"
                      placeholder="Existing TPA Name"
                      name="marketRenewal.existingTPA"
                      value={values.marketRenewal.existingTPA}
                      required={true}
                      onChange={handleChange}
                    />
                    <FieldErrorMessage name={`marketRenewal.existingTPA`} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Policy Period (From)"
                      variant="outlined"
                      placeholder="MM/DD/YY"
                      name="marketRenewal.policyPeriodFrom"
                      value={values.marketRenewal.policyPeriodFrom}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: new Date().toISOString().split('T')[0], 
                      }}
                      onChange={(e)=>{
                        getPolicyPeriodToValue(e.target.value)
                        handleChange(e)
                      }}
                    />
                    <FieldErrorMessage name={`marketRenewal.policyPeriodFrom`} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Policy Period (To)"
                      variant="outlined"
                      placeholder="MM/DD/YY"
                      name="marketRenewal.policyPeriodTo"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                      onChange={handleChange}
                      value={values.marketRenewal.policyPeriodTo}
                    />
                    <FieldErrorMessage name={`marketRenewal.policyPeriodTo`} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Claim Received Date"
                      variant="outlined"
                      placeholder="MM/DD/YY"
                      name="marketRenewal.recievedDateOfClaimData"
                      value={values.marketRenewal.recievedDateOfClaimData}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: values.marketRenewal.policyPeriodTo,
                        min: values.marketRenewal.policyPeriodFrom
                      }}
                      disabled={handleDisabled()}
                      onChange={handleChange}
                    />
                    <FieldErrorMessage name={`marketRenewal.recievedDateOfClaimData`} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Claim Paid"
                      variant="outlined"
                      placeholder="Claim Paid"
                      name="marketRenewal.claimPaid"
                      onChange={handleChange}
                      value={values.marketRenewal?.claimPaid}
                      type="number"
                    />
                    <FieldErrorMessage name={`marketRenewal.claimPaid`} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Claim Outstanding"
                      variant="outlined"
                      placeholder="Claim Outstanding"
                      name="marketRenewal.claimOutstanding"
                      value={values.marketRenewal.claimOutstanding}
                      onChange={handleChange}
                      required={true}
                      type="number"
                    />
                    <FieldErrorMessage name={`marketRenewal.claimOutstanding`} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Total Claims"
                      variant="outlined"
                      placeholder="Total Claims"
                      name="marketRenewal.totalClaim"
                      value={parseInt(values?.marketRenewal?.claimPaid||0) + parseInt(values?.marketRenewal?.claimOutstanding||0)}
                      disabled
                      required={true}
                      type="number"
                    />
                    <FieldErrorMessage name={`marketRenewal.totalClaim`} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Lives at Inception"
                      variant="outlined"
                      placeholder="Lives at Inception"
                      name="marketRenewal.liveAtInception"
                      value={values.marketRenewal.liveAtInception}
                      required={true}
                      onChange={handleChange}
                      type="number"
                    />
                    <FieldErrorMessage name={`marketRenewal.liveAtInception`} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Current Lives"
                      variant="outlined"
                      name="marketRenewal.currentLives"
                      value={values.marketRenewal.currentLives}
                      required={true}
                      onChange={handleChange}
                      type="number"
                    />
                    <FieldErrorMessage name={`marketRenewal.currentLives`} />
                  </Grid>
                </Grid>
                <Grid item xs={12} className="mt-3">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography fontSize='14px' fontWeight={500} padding={'8px 0px'}>Claim Dump*</Typography>
                      <Button
                        component="label"
                        role={undefined}
                        startIcon={<Icon icon='ic:outline-file-upload' />}
                        sx={{ border: '1px dashed', borderRadius: '8px', width: '-webkit-fill-available' }}>
                        Upload File
                        <VisuallyHiddenInput type="file" onChange={(e) => handleFileChange(e, 'claimDump')} />
                      </Button>
                      {values.marketRenewalDoc.claimDump && <Box padding={'4px'} bgcolor={'#E6F2FF'} marginTop={'12px'} borderRadius={'6px'}>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography fontSize='12px' fontWeight='700' color={'#3194FD'}>{values.marketRenewalDoc?.claimDump?.displayName}</Typography>
                           <IconButton size='small' aria-label="delete" onClick={()=>handleDeleteFile('claimDump')}>
                             <Icon icon="mdi:delete" width="20px" height="20px" style={{ color: '#FF0000' }} alt='Delete' />
                           </IconButton>
                        </Stack>
                        </Box>}
                        <FieldErrorMessage name={`marketRenewalDoc.claimDump`} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography fontSize='14px' fontWeight={500} padding={'8px 0px'}>Claim PDF*</Typography>
                      <Button
                        component="label"
                        role={undefined}
                        startIcon={<Icon icon='ic:outline-file-upload' />}
                        sx={{ border: '1px dashed', borderRadius: '8px', width: '-webkit-fill-available' }}>
                        Upload File
                        <VisuallyHiddenInput type="file" onChange={(e) => handleFileChange(e, 'claimPdf')} />

                      </Button>
                      {values.marketRenewalDoc.claimPdf && <Box padding={'4px'} bgcolor={'#E6F2FF'} marginTop={'12px'} borderRadius={'6px'}>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography fontSize='12px' fontWeight='700' color={'#3194FD'}>{values.marketRenewalDoc?.claimPdf?.displayName}</Typography>
                          <IconButton size='small' aria-label="delete" onClick={()=>handleDeleteFile('claimPdf')}>
                             <Icon icon="mdi:delete" width="20px" height="20px" style={{ color: '#FF0000' }} alt='Delete' />
                           </IconButton>
                        </Stack>
                      </Box>
                      }
                      <FieldErrorMessage name={`marketRenewalDoc.claimPdf`} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <FormGroup padding={'16px'} >
                  <FormControlLabel control={<Checkbox onChange={handleChange} name="agreeToTerms"/>} label={<Typography fontSize='13px' color={'#666666'}>I hereby certify, that the expiring policy schedule and claims dump uploaded is certified by the TPA/insurance company and quote generated only stands valid if the above is true *.</Typography>} />
                </FormGroup>
                <FieldErrorMessage name={`agreeToTerms`} />
              </Grid>
            </Grid>
          </>}
        </Box>
      </Stack>
      <SubmitFooter showBack={true} handleSubmit={() => nextStep()} />
    </Fragment>
  );
};

export default InsuranceDetails;

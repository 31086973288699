import React from "react"
import { Modal } from "react-responsive-modal";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button"
import { Icon } from "@iconify/react/dist/iconify.js";
import 'react-responsive-modal/styles.css';
import './modals.css'
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { maskEmail } from "../../utils";

const ShareSuccess = ({ handleClose }) => {
    const navigate = useNavigate();
    const insureDetails = JSON.parse(sessionStorage.getItem("insureDetails"))
    const companyDetails = insureDetails && insureDetails.companyDetails


    return <Modal open={true} showCloseIcon={false} closeOnOverlayClick={false} closeOnEsc={false} center blockScroll={true}
        classNames={{
            overlay: 'sessionOverlay',
            modal: 'sessionModal',
        }}>
        <div style={{
            textAlign: 'center'
        }}>
            <IconButton sx={{ position: 'absolute', right: '2px', top: '4px' }} onClick={() => handleClose()}>
                <Icon icon="radix-icons:cross-2" fontSize={'18px'} color='#000000' />
            </IconButton>
            <img alt="session expired" src="/images/checkmark.svg" />
            <div style={{ fontSize: '18px', fontWeight: '600', padding: '10px 0px' }}>Quote Shared Successfully!</div>
            <div style={{ padding: '10px 0px 0px 0px', color: '#808080', fontSize: '14px' }}>Quote has been shared successfully at </div>
            {companyDetails && companyDetails.emailId &&
                <Typography marginBottom={'20px'} fontSize={'14px'} fontWeight={700}>{maskEmail(companyDetails.emailId)}</Typography>
            }
            <Button
                variant="contained"
                onClick={() => {
                    navigate("/Dashboard");
                }}
            >
                My Dashboard
            </Button>
        </div>
    </Modal>
}

export default ShareSuccess
import React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import { useNavigate } from 'react-router-dom';

const SubmitFooter = ({ handleSubmit, showBack }) => {

    const navigate = useNavigate();

    return <Box
        position={'fixed'}
        bottom={'0px'}
        padding={'12px'}
        zIndex={10}
        width={'100%'}
        bgcolor={'#ffffff'}
        border={'1px solid #e1e1e1'}
    >
        <Stack flexDirection={'row'} gap={'20px'} justifyContent={'center'} alignItems={'center'}>
            {showBack && <Button variant="outlined" onClick={() => navigate(-1)} sx={{ padding: '12px 40px', height: '50px', width: '140px' }}>Back</Button>}
            <Button variant="contained" onClick={() => handleSubmit()} sx={{ padding: '12px 40px', height: '50px', width: '140px' }}>Continue</Button>
        </Stack>
    </Box>
}

export default SubmitFooter
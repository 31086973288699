export const opdValues = [
    4000,
    4500,
    5000,
    5500,
    6000,
    6500,
    7000,
    7500,
    8000,
    8500,
    9000,
    9500,
    10000
]

export const healthCheckUpValues = [
    3500,
    4000,
    4500,
    5000,
]

export const corporateBufferValues = [
    { value: 100000, name: '1 Lakh' },
    { value: 200000, name: '2 Lakhs' },
    { value: 300000, name: '3 Lakhs' },
    { value: 400000, name: '4 Lakhs' },
    { value: 500000, name: '5 Lakhs' },
    { value: 600000, name: '6 Lakhs' },
    { value: 700000, name: '7 Lakhs' },
    { value: 800000, name: '8 Lakhs' },
    { value: 900000, name: '9 Lakhs' },
    { value: 1000000, name: '10 Lakhs' },
]


export const maternityOptionValues = [
    'Normal – ₹ 25,000, Caesarean – ₹ 50,000',
    'Normal – ₹ 30,000, Caesarean – ₹ 55,000',
    'Normal – ₹ 35,000, Caesarean – ₹ 60,000',
    'Normal – ₹ 40,000, Caesarean – ₹ 65,000',
    'Normal – ₹ 45,000, Caesarean – ₹ 70,000',
    'Normal – ₹ 50,000, Caesarean – ₹ 75,000',
]

export const hashedMaternityValues = {
    "Normal – ₹ 25,000, Caesarean – ₹ 50,000": { normal: 25000, caesarean: 50000 },
    "Normal – ₹ 30,000, Caesarean – ₹ 55,000": { normal: 30000, caesarean: 55000 },
    "Normal – ₹ 35,000, Caesarean – ₹ 60,000": { normal: 35000, caesarean: 60000 },
    "Normal – ₹ 40,000, Caesarean – ₹ 65,000": { normal: 40000, caesarean: 65000 },
    "Normal – ₹ 45,000, Caesarean – ₹ 70,000": { normal: 45000, caesarean: 70000 },
    "Normal – ₹ 50,000, Caesarean – ₹ 75,000": { normal: 50000, caesarean: 75000 }
}

export const maternityDefaultValues = {
    25000:"Normal – ₹ 25,000, Caesarean – ₹ 50,000",
    30000:'Normal – ₹ 30,000, Caesarean – ₹ 55,000',
    35000:'Normal – ₹ 35,000, Caesarean – ₹ 60,000',
    40000:'Normal – ₹ 40,000, Caesarean – ₹ 65,000',
    45000:'Normal – ₹ 45,000, Caesarean – ₹ 70,000',
    50000:'Normal – ₹ 50,000, Caesarean – ₹ 75,000',
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-tooltip{border: 1px solid #F8BE10;
    background: #FFF7DD;
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #C69400;
    font-size: 12px;
    padding: 8px;
    border-radius: 5px;
    width: -moz-fit-content;
    width: fit-content;
    margin: 20px auto ;
}
.icon-padding{
    padding-right: 30px !important;
}
.company-input{
    height: 45px !important;
    border-radius: 8px !important;    
}`, "",{"version":3,"sources":["webpack://./src/components/company-details/companyDetails.css"],"names":[],"mappings":"AAAA,cAAc,yBAAyB;IACnC,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,cAAc;IACd,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,uBAAkB;IAAlB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,8BAA8B;AAClC;AACA;IACI,uBAAuB;IACvB,6BAA6B;AACjC","sourcesContent":[".info-tooltip{border: 1px solid #F8BE10;\n    background: #FFF7DD;\n    justify-content: space-between;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    color: #C69400;\n    font-size: 12px;\n    padding: 8px;\n    border-radius: 5px;\n    width: fit-content;\n    margin: 20px auto ;\n}\n.icon-padding{\n    padding-right: 30px !important;\n}\n.company-input{\n    height: 45px !important;\n    border-radius: 8px !important;    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

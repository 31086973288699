import React from 'react';
import {Chip} from '@mui/material'

type StatusBadge = {
    key: string;
    label: string;
    color: string;
};

type Props = {
    badges: StatusBadge[];
    selectedKey: string; 
    children?:React.ReactNode
};

//Danger: Dont change UI without looking for potential breakage at other places

const SmeStatusBadges: React.FC<Props> = ({ badges, selectedKey }) => {
   
    const selectedBadge = badges.find(badge => badge.key === selectedKey);

    if (!selectedBadge) {
        return null; 
    }

    return (
        <Chip key={selectedBadge.key} label={selectedBadge.label} color='success'  style={{backgroundColor:selectedBadge.color, color:'black', fontWeight:'500', borderRadius:'4px' }} />  
        
    );
};

export default SmeStatusBadges;
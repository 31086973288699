import React from "react"
import LeadCard from "./LeadCard";
import { Typography, Unstable_Grid2 } from '@mui/material';

type CompanyDetailsType = {
  companyId: string;
  companyName: string;
  industry?: string;
  address?: string;
  pincode?: string;
  name: string;
  emailId: string;
  phoneNo: string;
};

export type CalculatedPremiumType = {
  basePremium: number,
  maternityPremium: number,
  customizedPremium:number
}

export type FamilyDetailsType = {
  [key: string]: {
    [key: string]: string;
  };
};

export type MarketRenewalDocType = {
  documentId?: string;
  displayName?: string;
  documentType?: string;
  responseMessage?: string;
  presignedUrl?:string
  category?: string;
};

type LeadDataType = {
  leadId: string;
  user: string;
  userType: string;
  companyDetails: CompanyDetailsType;
  planType: string;
  sumInsured: string[];
  familyDetails: FamilyDetailsType;
  policyType: string;
  marketRenewalDoc: MarketRenewalDocType[];
  quoteDownloadedTypes: string[];
  createdAt: string;
  premium:CalculatedPremiumType
  leadStatus:string
};

export type LeadCardContainerPropTypes = {
  leadData?: LeadDataType[]

}

const LeadCardContainer = ({ leadData }: LeadCardContainerPropTypes) => {
  if (!leadData?.length) {
    return <Unstable_Grid2 container sx={{ justifyContent: 'center' }}>
      <Typography variant="h4">No Data</Typography>
    </Unstable_Grid2>
  }
  return <>
    {
      leadData.map(lead => {
        return <LeadCard companyId={lead?.companyDetails?.companyId} leadId={lead.leadId} calculatedOutput={lead.premium} documentList={lead.marketRenewalDoc}
          familyDetails={lead.familyDetails} quoteGenerated={lead.quoteDownloadedTypes}
          sumInsured={lead.sumInsured} key={lead.leadId} planType={lead.planType}
          user={lead.user} userType={lead.userType} policyType={lead.policyType}
          createdAt={new Date(lead.createdAt)} emailId={lead.companyDetails?.emailId}
          leadStatus={lead.leadStatus}
          corporateName={lead.companyDetails?.companyName} contactName={lead.companyDetails?.name} contactNumber={lead.companyDetails?.phoneNo} />
      })
    }
  </>
}


export default LeadCardContainer
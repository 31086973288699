import React, { useEffect, useState } from "react";
import axios from "axios";
import QuoteCard from "./quoteCard";
import { API_BASE_URL } from "../../config";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { getCookie } from "../../utils";
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography";
import SmeStepper from "../common/Stepper/Stepper";
import List from "@mui/material/List";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import ViewBenefits from "../modals/ViewBenefitsModals";
import SmeSnackbar from "../common/Snackbar";

const Quote = ({ sessionHandler }) => {
  const [quoteData, setQuoteData] = useState(null);
  const [quoteStatus, setQuoteStatus] = useState(false);
  const [showBenefits, setShowBenefits] = useState({display:false,cateriories:{}})
  const [showCustomizeCard, setShowCustomizeCard] = useState(true);
  const voAuthToken = getCookie('vo-auth-token')
  const agent = getCookie('vo-login-id')
  const leadDetails = JSON.parse(sessionStorage.getItem("leadDetails"))
  const [snackBarDetails,setSnackBarDetails] = useState({display:'',message:''})

  const notesListItems = [
    "Ensure RFQ is in place",
    "Share the quote to Partner to take their approval",
    "Collect as much documents/information as possible - checklist, approved quote, list of insured person, cheque scan copy, PAN copy, bank details, client request mail.",
    " Submit the required documents along with cheque to the nearest branch.",
    " Follow up on Quote analysis / processing and incorporation of terms and conditions to close the deal.",
  ]

  const getPremium = () => {
    if(leadDetails?.leadId){
    axios
      .get(`${API_BASE_URL}/sme-api/lead/${leadDetails.leadId}/premium`, {
        headers: {
          user: agent,
          token: voAuthToken
        },
      })
      .then(( {data} ) => {        
        if (
          data.data.sumInsured.length === 1 &&
          data.data.sumInsured.includes("2 Lakhs")
        )
          setShowCustomizeCard(false);
        setQuoteData(data.data);
        setQuoteStatus(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.error('Bad Request:', error.response.data);
          sessionHandler()
        }
      });
    }
  };

  useEffect(() => {
    getPremium();
  }, []);

  const handleDiscountUpdate= (data) =>{
      if(data && data.quoteType==='base'){
        setQuoteData((prev)=>
           {
            return {...prev,
                insurancePremium:{...prev.insurancePremium,basePremium:data.discountedPremium},
                earnings:{...prev.earnings,baseEarning:data.earning},
                commissionAndDiscount:{...prev.commissionAndDiscount,base:{
                  ...prev.commissionAndDiscount.base,commission:data.commission, discount:data.discount
                }}
            }
        })
      }
      else if(data && data.quoteType==='maternity'){
        setQuoteData((prev)=>
          {
           return {...prev,
               insurancePremium:{...prev.insurancePremium,maternityPremium:data.discountedPremium},
               earnings:{...prev.earnings,maternityEarning:data.earning},
               commissionAndDiscount:{...prev.commissionAndDiscount,maternity:{
                 ...prev.commissionAndDiscount.maternity,commission:data.commission, discount:data.discount
               }}
           }
       })
      }
      else if(data && data.quoteType==='customized'){
        setQuoteData((prev)=>
          {
           return {...prev,
               insurancePremium:{...prev.insurancePremium,customizedPremium:data.discountedPremium},
               earnings:{...prev.earnings,customizedEarning:data.earning},
               commissionAndDiscount:{...prev.commissionAndDiscount,customized:{
                 ...prev.commissionAndDiscount.customized,commission:data.commission, discount:data.discount
               }}
           }
       })
      }
  }

  return (
    <>
      <SmeSnackbar open={snackBarDetails.display} message={snackBarDetails.message} handleClose={()=>setSnackBarDetails({display:false})} />
      {showBenefits.display && <ViewBenefits cateriories={showBenefits.cateriories} onClose={() => setShowBenefits((prev)=>({...prev,display:false}))} />}
      <SmeStepper />
      <Container className="quote-wrapper ">
        <Box className="col-sm-12">
          <Typography fontSize={'18px'} fontWeight={'600'}>Quote Generated</Typography>
          <Typography fontSize={'14px'} color={'#666666'}>Checkout below recommended plans</Typography>
        </Box>
        {quoteStatus ? (
          <Stack flexDirection='row' padding={'30px 0px'}>
            <div className="col-sm-12">
              <div className="">
                <Swiper
                  slidesPerView={1.1}
                  spaceBetween={10}
                  centeredSlides={true}
                  //loop={window.innerWidth < 600 ? true : false}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                  modules={[Pagination]}
                  className="mySwiper quote-swiper"
                >
                  {quoteData.insurancePremium.basePremium &&  quoteData?.policyType === "market_renewal" && (
                    <SwiperSlide>
                      <QuoteCard
                        cardTitle="Base"
                        tag="Basic"
                        leadId={quoteData.leadId}
                        type="base"
                        premium={quoteData.insurancePremium.basePremium}
                        totalEmp={quoteData.peoplesCount}
                        company={quoteData.companyDetails}
                        totalPeopleCount={quoteData.insurancePremium.peopleCount}
                        leadType={quoteData?.policyType}
                        earning={quoteData?.earnings?.baseEarning}
                        showBenefits={() => setShowBenefits((prev)=>({...prev,display:true,cateriories:{}}))}
                        discountDetails={quoteData?.commissionAndDiscount?.base}
                        handleSnackBar={(details)=>setSnackBarDetails(details)}
                        handleDiscountUpdate={(data)=>handleDiscountUpdate(data)}
                        sessionHandler={sessionHandler}
                      />
                    </SwiperSlide>
                  )}
                  {/* {quoteData.insurancePremium.maternityPremium && quoteData?.policyType !== "market_renewal" && (
                    <SwiperSlide>
                      <QuoteCard
                        cardTitle="Base + Maternity"
                        tag="Maternity"
                        leadId={quoteData.leadId}
                        premium={
                          quoteData.insurancePremium.maternityPremium
                        }
                        type="maternity"
                        totalEmp={quoteData.peoplesCount}
                        company={quoteData.companyDetails}
                        totalPeopleCount={quoteData.insurancePremium.peopleCount}
                        showBenefits={(cateriories={}) => setShowBenefits((prev)=>({display:true,cateriories:{...prev.cateriories,...cateriories}}))}
                        earning={quoteData?.earnings?.maternityEarning}
                        discountDetails={quoteData?.commissionAndDiscount?.maternity}
                        handleSnackBar={(details)=>setSnackBarDetails(details)}
                        handleDiscountUpdate={(data)=>handleDiscountUpdate(data)}
                        sessionHandler={sessionHandler}
                      />
                    </SwiperSlide>
                  )} */}
                  {/* {quoteData.insurancePremium.totalPremium.withBuffer && (
                  <SwiperSlide>
                    <QuoteCard
                      cardTitle="Base + Buffer"
                      tag="With Buffer"
                      premium={
                        quoteData.insurancePremium.totalPremium.withBuffer
                      }
                      totalEmp={quoteData.insurancePremium.totalEmployee}
                      company={quoteData.companyDetails}
                    />
                  </SwiperSlide>
                )} */}
                  {
                    quoteData.insurancePremium.customizedPremium && quoteData?.policyType !== "market_renewal" && (
                      <SwiperSlide>
                        <QuoteCard
                          cardTitle="Base + Customised"
                          tag="Customized"
                          premium={
                            quoteData.insurancePremium.customizedPremium
                          }
                          type="customized"
                          leadId={quoteData.leadId}
                          totalEmp={quoteData.peoplesCount}
                          company={quoteData.companyDetails}
                          callPremium={getPremium}
                          addon={quoteData.addOns}
                          totalPeopleCount={quoteData.insurancePremium.peopleCount}
                          onlyEnployee={
                            quoteData.insurancePremium.isEmployeeOnly
                              ? quoteData.insurancePremium.isEmployeeOnly
                              : false
                          }
                          showBenefits={(cateriories={}) => setShowBenefits((prev)=>({display:true,cateriories:{...prev.cateriories,...cateriories}}))}
                          earning={quoteData?.earnings?.customizedEarning}
                          discountDetails={quoteData?.commissionAndDiscount?.customized}
                          handleSnackBar={(details)=>setSnackBarDetails(details)}
                          handleDiscountUpdate={(data)=>handleDiscountUpdate(data)}
                          sessionHandler={sessionHandler}
                          is2LakhSI={showCustomizeCard}
                        />
                      </SwiperSlide>
                    )}
                </Swiper>
              </div>
            </div>
            {/* <div className="col-sm-12">
            <button className="btn btn-primary">Download Quote</button>
            <button className="btn btn-primary"></button>
          </div> */}
          </Stack>
        ) : null}
        {/* {quoteData?.policyType === "market_renewal" && <div style={{ padding: '50px 0px 0px 0px', fontWeight: 600 }}>
          *The premium displayed / approved is indicative only. However kindly contact corporate office for final approval of the quote.
        </div>} */}
        <Box
          sx={{ padding: '16px 24px' }}
          boxShadow={'0px 2px 24px 0px #0000000D'}
          borderRadius={'17px'}
          bgcolor={'#ffffff'}
          marginTop={'60px'}
        >
          <Typography fontSize={'16px'} fontWeight={'700'}>Note</Typography>
          <List>
            {notesListItems.map((each, index) => {
              return <Box key={index} >
                <Typography fontSize={'14px'}>{`${index + 1}. ${each}`}</Typography>
              </Box>
            })}
          </List>
        </Box>
        <Box
          sx={{ padding: '16px 24px' }}
          boxShadow={'0px 2px 24px 0px #0000000D'}
          borderRadius={'17px'}
          bgcolor={'#ffffff'}
          marginTop={'16px'}
        >
          <Typography fontSize={'16px'} fontWeight={'700'}>Terms and Conditions</Typography>
          <Typography fontSize={'14px'}>* Prices above are exclusive of GST.</Typography>
        </Box>
      </Container>
    </>
  );
};

export default Quote;

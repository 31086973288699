import { Modal } from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import './modals.css'
import React, { useEffect,useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack"
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton";
import { Icon } from "@iconify/react/dist/iconify.js";

const ViewBenefits = ({ onClose ,cateriories}) => {

    const roomBenifits = ['Pre Hospitalisation - 30 Days', 'Post Hospitalisation - 60 Days', 'Pre hospitalisation expenses incurred prior to inception of policy with the Company is inadmissible']

    const  genericBeneifts = [
        {
            head: 'Room rent limits including Boarding, Nursing Charges:',
            element: <Box>
                <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>If the Insured occupies a room/ICU with a room rent limit other than his eligibility as per the insurance policy, then all the other charges shall be limited to the charges applicable for the eligible room rentor actuals, whichever is lower.</Typography>
                <List
                    sx={{
                        listStyleType: 'disc',
                        listStylePosition: 'inside'
                    }}
                >
                    {roomBenifits.map((each, index) => { return <ListItem key={index} sx={{ display: 'list-item', color: '#4d4d4d', fontSize: '14px', fontWeight: '400', padding: '2px' }}>{each}</ListItem> })}
                </List>
            </Box>
        },
        {
            head: 'Ambulance Expenses limits:',
            element: <>
                <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>₹ 1,000/- per hospitalisation and ₹ 2,000/- per policy period</Typography>
            </>
        },
        {
            head: 'Sub Limits:',
            element: <>
                <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>Sub limits only for cataract</Typography>
                <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>For sum Insured of ₹ 2,00,000/- and above : ₹ 20,000/- per eye</Typography>
            </>
        },
        {
            head: 'Addition of Employees:',
            element: <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>After the inception of the Policy, No mid-term inclusion of any employee & dependants unless he is a new joinee and dependents of the already insured employee unless they are newly married spouse and new born child and such inclusion is also subject to payment of additional premium on pro rata basis. For newly joined employees, the Insured shall provide the date of joining & for inclusion of dependents of the already insured employees, the Insured should provide the date of marriage for newly married spouse & date of birth for newly born child.</Typography>
        },
        {
            head: 'Deletion of Employees:',
            element: <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>The coverage under the policy in respect of the insured persons will cease once they cease to be an employee/ member of the Insured or on the expiry of the policy, whichever is earlier. In respect of deletions, refund will be effected on prorata basis from the date of deletion of the employee under the policy - subject to NO claim for the employee or the family members, for which the Insured shall provide date of relieving of the employee.</Typography>
        },
        {
            head: 'Claim Settlement:',
            element: <><Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>Claims will be settled through In house claims team.</Typography></>
        },
        {
            head: 'Treatment in network hospital only:',
            element: <>
                <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>Treatment in our network hospitals only, However in the case of Medical Emergencies treatment can be taken in other Hospitals. In all cases immediate intimation shall be given to our Call Center within 24 hours of Hospitalisation</Typography>
            </>
        },
        {
            head: 'Day Care Procedure:',
            element: <>
                <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>All Day Care Procedures covered.</Typography>
            </>
        },
        {
            head: 'ID Card:',
            element: <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>We shall issue ID cards in respect of all the covered persons.</Typography>
        },
        {
            head: 'Increase / Decrease in Group size:',
            element: <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>The quote is given for the above mentioned specific population. In case of any increase or decrease in the population, the premium will vary.</Typography>
        },
        {
            head: 'Dependent Child:',
            element: <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>Dependent Child means a child (natural or legally adopted) who is financially dependent and does not have his or her independent source of income and not over 30 years.</Typography>
        }
    ]


    const maternityBenefits = [
        {
            head: 'Maternity Benefits:',
            element: <>
                <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>Normal - Rs. 25000 Caesarean - Rs. 50000</Typography>
                <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>Maternity benefits, applicable only for the Employee or Dependent spouse.</Typography>
                <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>Pre and Post Natal Expenses Limit - Rs. 5000/- (within mentioned maternity limits).</Typography>
            </>
        },
        {
            head: 'Inclusion of New born:',
            element: <Typography fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>The benefit payable hereunder shall be up to full floater sum insured.</Typography>
        }
    ]

    const healthCheckUpBenefits = [{
        head: 'Health Checkup limits :',
        element:<>
        <List
            sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside'
                }}
                >
                <ListItem  sx={{ display: 'list-item', color: '#4d4d4d', fontSize: '14px', fontWeight: '400', padding: '2px' }}>
                    Health Checkup limits as per selection per family
                </ListItem>
                <ListItem  sx={{ display: 'list-item', color: '#4d4d4d', fontSize: '14px', fontWeight: '400', padding: '2px' }}>This benefit is payable, when the insured visits a clinic / hospital / associated facility / consultation for diagnosis and treatment based on the advice
                of a medical practitioner.</ListItem>
        </List>
        </>
        }
    ]

    const  corporateBufferBenefits = [{
        head: 'Corporate Buffer :',
        element:<>
        <List
            sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside'
                }}
                >
                <ListItem  sx={{ display: 'list-item', color: '#4d4d4d', fontSize: '14px', fontWeight: '400', padding: '2px' }}>Corporate Buffer limit restricted to Individual / Family Sum Insured.</ListItem>
                <ListItem  sx={{ display: 'list-item', color: '#4d4d4d', fontSize: '14px', fontWeight: '400', padding: '2px' }}>Corporate buffer benefit can be utilized for all ailments.</ListItem>
        </List>
        </>
        }
    ]

    const outpatientBenifts =[{
        head:'Out Patient Treatment limits :',
        element:<>
           <List
            sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside'
                }}
                >
                <ListItem  sx={{ display: 'list-item', color: '#4d4d4d', fontSize: '14px', fontWeight: '400', padding: '2px' }}>Out Patient limits as per selection per family</ListItem>
                <ListItem  sx={{ display: 'list-item', color: '#4d4d4d', fontSize: '14px', fontWeight: '400', padding: '2px' }}>This benefit is payable, when the insured visits a clinic / hospital / associated facility / consultation for diagnosis and treatment based on the advice
                of a medical practitioner.</ListItem>
        </List>
        </>
    }]

    const addOnMaternityBenifts =[{
        head:'Maternity :',
        element:<>
           <List
            sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside'
                }}
                >
                <ListItem  sx={{ display: 'list-item', color: '#4d4d4d', fontSize: '14px', fontWeight: '600', padding: '2px' }}>Maternity Benefits:</ListItem>
                <Typography marginLeft={'20px'} fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>Maternity benefits, applicable only for the Employee or Dependent spouse.</Typography>
                <Typography marginLeft={'20px'} fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>Pre and Post Natal Expenses Limit - Rs. 5000/- (within mentioned maternity limits)</Typography>
                <ListItem  sx={{ display: 'list-item', color: '#4d4d4d', fontSize: '14px', fontWeight: '600', padding: '2px' }}>Inclusion of New born:</ListItem>
                <Typography marginLeft={'20px'} fontWeight={400} color={'#4D4D4D'} fontSize={'14px'}>The benefit payable hereunder shall be up to full floater sum insured.</Typography>
        </List>
        </>
    }]


    const [allBenefits,setAllBenifits] = useState(genericBeneifts)
    const [extraBenefits,setExtraBenefits] = useState([])

    useEffect(()=>{
        if(cateriories.isMaternity){
            setAllBenifits([...allBenefits,...maternityBenefits])
        }
        if(cateriories.isOnAddOnMaternity){
            setExtraBenefits((prev)=>[...prev,...addOnMaternityBenifts])
        }
        if(cateriories.isCBuffer){
            setExtraBenefits((prev)=>[...prev,...corporateBufferBenefits])
        }
        if(cateriories.isOPD){
            setExtraBenefits((prev)=>[...prev,...outpatientBenifts])
        }
        if(cateriories.isHealth){
            setExtraBenefits((prev)=>[...prev,...healthCheckUpBenefits])
        }
    },[cateriories])


    return <Modal
        onClose={() => onClose()}
        open={true} showCloseIcon={false}
        center
        classNames={{
            overlay: 'sessionOverlay',
            modal: 'benefitsModal'
        }}
    >
        <Box
            padding={'14px 0px'}
            bgcolor={'#ffffff'}
            top={0}
            position={'sticky'}
            zIndex={10}
            padd
            >
            <Stack flexDirection={'row'}  alignItems={'center'} justifyContent={'space-between'} >
                <Typography marginBottom={'4px'} fontWeight={'700'}>View Benefits</Typography>
                <IconButton  onClick={()=>onClose()} sx={{padding:'2px'}}>
                    <Icon icon="bitcoin-icons:cross-filled" width="20px" height="20px" style={{ color: '#000000' }} alt='Delete' />    
                </IconButton>    
            </Stack>
            <Divider />
        </Box>
        <Box>
            {allBenefits.map((each, index) => {
                return <Stack gap={'10px'} paddingBottom={'20px'}>
                    <Typography fontWeight={600} fontSize={'15px'}>{each.head}</Typography>
                    <>
                        {each.element}
                    </>
                </Stack>
            })}
            {extraBenefits && extraBenefits.length>0 && <Typography fontWeight={400} padding={'20px 0px'} color={'#4D4D4D'} fontSize={'14px'} textTransform={"uppercase"}>Dependent on Selection</Typography>}
            {extraBenefits.map((each) => {
                return <Stack gap={'10px'} paddingBottom={'20px'}>
                    <Typography fontWeight={600} fontSize={'15px'}>{each.head}</Typography>
                    <>
                        {each.element}
                    </>
                </Stack>
            })}
            <Stack
                flexDirection={'row'}
                alignContent={'center'}
                justifyContent={'center'}
            >
                <Typography fontWeight={400} fontSize={'13px'} color={'#4D4D4D'}>------------ All other terms and conditions as per SGHI Policy Clause ------------</Typography>
            </Stack>
        </Box>
    </Modal>
}

export default ViewBenefits
import React from "react";

const Support = (props) => (
  <div className="col-md-12 order-md-2 mb-3">
    <div className="sidebar-block">
      <div className="media mt-3 text-left">
        {props.imgName !== "noimg" ? (
          <div className="w-50px h-50px d-flex justify-content-center align-items-center bg-white rounded-circle text-center">
          <img
            src={
              props.imgName
                ? `/images/${props.imgName}`
                : "/assets/img/person.png"
            }
            className="align-self-center"
            alt="..."
            />
            </div>
        ) : null}
        <div className="media-body align-self-center ml-3">
          {props?.support?.title ? (
            <h6 className="font-weight-bold">{props.support.title}</h6>
          ) : null}
          {props?.support?.desc ? (
            <p className="text-muted fs-14">
              {props.support.descTitle ? (
                <strong className="font-weight-bold color-blk">
                  {props.support.descTitle}{" "}
                </strong>
              ) : null}
              {props?.support?.desc
                ? props.support.desc
                : "Request a callback, sit back Relax!"}
            </p>
          ) : null}
          {props?.support?.cta && (
            <button className="btn btn-link btn-sm btn-txt-blue p-0 fs-16">
              {props.support.cta
                ? props.support.cta
                : "Talk to our experts > >"}
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default Support;

import React,{ useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import StepIcon from '@mui/material/StepIcon';

const SmeStepper = () => {

  const steps  = ['Corporate Details', 'Employee Details','Quote']  
  const [activeStep, setActiveStep] = useState(2);
  const [completed, setCompleted] = useState({0:true,1:true});
  const { pathname } = window.location

  const CustomStepLabel = styled(StepLabel)(({ theme, active, completed }) => ({
    '& .MuiStepLabel-label': {
      color: active ? '#FFA439 !important' : completed ? '#19B100 !important' : 'inherit',
    },
  }));

  useEffect(()=>{
    if(pathname==='/company-details'){
      setActiveStep(0)
      setCompleted({0:false})
    }
    else if(pathname==='/lead')
    {
      setActiveStep(1)
      setCompleted({0:true,1:false})
    }
    else if(pathname==='/quote'){
      setActiveStep(2)
      setCompleted({0:true,1:true, 2:true})
    }
  },[pathname])

  const CustomStepIcon = ({ active, completed, className ,icon}) => {
    return (
      <StepIcon
        className={className}
        completed={completed}
        active={active}
        icon={<div style={{ 
                   padding:'10px',
                   height:'25px',
                   fontSize:'12px',
                   width:'25px',
                   borderRadius:'50%',
                   color:active ? '#ffffff' : completed ? '#ffffff' : 'inherit', 
                   fontWeight:600,
                   display:'flex',
                   border: active || completed ? 'none' : '1px solid #cccccc',
                   alignItems:'center', 
                   justifyContent:'center',
                   backgroundColor: active ? '#FFA439' : completed ? '#19B100' : 'inherit' }}>
                    {<>{icon}</>}</div>
              }
      />
    );
  };
  

  return (
    <Container  sx={{padding:'30px 10px', width:{lg:'50%'}}}>
    <Stepper nonLinear activeStep={activeStep}>
    {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}
            className={
            completed[index]
              ? 'completed-step-line'
              : index === activeStep
              ? 'active-step-line'
              : 'step-line'
            }
          >
            <Box color="inherit">
              <CustomStepLabel
                StepIconComponent={CustomStepIcon}
                active={index === activeStep}
                completed={!!completed[index]}
                displayValue={index+1}
              >
                {label}
              </CustomStepLabel>
            </Box>
          </Step>
        ))}
  </Stepper>
  </Container>
  );
};

export default SmeStepper;

import React, { useEffect, useMemo, useState } from "react";
import { CalculatedPremiumType, FamilyDetailsType, MarketRenewalDocType } from "./LeadCardsContainer";
import { dateFormatMMMDDYYYY } from "../../utils/date.util";
import '../modals/modals.css';
import SmeStatusBadges from "../common/sme-badge";
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Menu, MenuItem, Typography, Unstable_Grid2, Dialog, DialogContent, DialogTitle, Button, DialogActions } from '@mui/material';
import { Icon } from '@iconify/react';
import IconButton from '@mui/material/IconButton';
import { DASBHBOARD_BLUE_COLOR, DocumentType, DocumentTypeLabel, enumsLabelType, FamilyDetailAge, LeadStatus, LeadStatusLabel, PolicyType, PolicyTypeLabel, QuoteType } from "./dashboard.constants";
import { LeadApiService, toBase64 } from "./lead-api.service";
import { UploadedFileType, DynamicFileUploader } from "../common/FileUploder";
import useSnackBars from "../common/snackbar/SmeSnackbar";
import axios from "axios";


export const WebloaderTS = (status?: string) => {
    if (status === "show") {
        document.querySelector(".WebLoader")?.classList?.remove("d-none");
    } else {
        document.querySelector(".WebLoader")?.classList?.add("d-none");
    }
};

export function getCookie(cname: string) {
    let name = cname + '=';
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

const boldText: any = {
    fontWeight: 500,
    wordWrap: "break-word"
}

const labelText = {
    fontWeight: 400,
    color: '#808080'

}



const LeadStatusBadge = [
    {
        key: LeadStatus.DRAFT_SAVED,
        label: LeadStatusLabel[LeadStatus.DRAFT_SAVED],
        color: '#FFCCCC'
    },
    {
        color: '#FFDECC',
        key: LeadStatus.LEAD_GENERATED,
        label: LeadStatusLabel[LeadStatus.LEAD_GENERATED]
    },
    {
        color: '#CCDAFF',
        key: LeadStatus.PAYMENT_DONE,
        label: LeadStatusLabel[LeadStatus.PAYMENT_DONE]
    },
    {
        color: '#CCF6FF',
        key: LeadStatus.PAYMENT_INITIATED,
        label: LeadStatusLabel[LeadStatus.PAYMENT_INITIATED]
    },
    {
        color: '#FFF7CC',
        key: LeadStatus.QUOTE_DOWNLOADED,
        label: LeadStatusLabel[LeadStatus.QUOTE_DOWNLOADED]
    },
    {
        color: '#D6FFCC',
        key: LeadStatus.QUOTE_SHARED,
        label: LeadStatusLabel[LeadStatus.QUOTE_SHARED]
    }
]

const FamilyDetailAgeLabel: enumsLabelType = {
    [FamilyDetailAge.emp]: 'Emp (36-65 Age)',
    [FamilyDetailAge.emp18]: 'Emp (18-35 Age)',
    [FamilyDetailAge.emp36]: 'Emp (36-45 Age)',
    [FamilyDetailAge.emp46]: 'Emp (46-55 Age)',
    [FamilyDetailAge.emp56]: 'Emp (56-65 Age)',
    [FamilyDetailAge.child]: 'Child',
    [FamilyDetailAge.inlaws]: 'In Laws',
    [FamilyDetailAge.mother]: 'Mother',
    [FamilyDetailAge.spouse]: 'Spouse',
    [FamilyDetailAge.parents]: 'Parents'
}

type LeadCardPropTypes = {
    corporateName: string,
    contactName: string,
    contactNumber: string,
    emailId: string,
    createdAt: Date | number,
    policyType: string,
    planType: string,
    user: string,
    userType: string,
    quoteGenerated: string[],
    sumInsured: string[],
    familyDetails: FamilyDetailsType
    documentList: MarketRenewalDocType[],
    calculatedOutput: CalculatedPremiumType,
    leadStatus: string,
    leadId: number | string,
    companyId: string
}


const DataLabel = ({ label, value }: { label: string, value: string | number | React.ReactNode, children?: React.ReactNode }) => {
    return < >
        <Typography variant="caption" style={labelText}>{label}</Typography>
        <Typography variant="body2" style={{ fontWeight: 500, wordWrap: "break-word" }}>{value}</Typography>
    </>
}

type FamilyDetailsModalPropTypes = {
    detail: { [key: string]: string } | null,
    toggle: () => void,
    sumInsured: string,
    planType: string
}

const FamilyDetailModal = (props: FamilyDetailsModalPropTypes) => {

    const updatedFamilyData = useMemo(() => {
        const data = { ...props.detail }
        // if (data && data[FamilyDetailAge.emp] && data[FamilyDetailAge.emp18]) {
        //     data[FamilyDetailAge.emp18] = data[FamilyDetailAge.emp18] + data[FamilyDetailAge.emp];
        // } else if (data && !data[FamilyDetailAge.emp18] && data[FamilyDetailAge.emp]) {
        //     data[FamilyDetailAge.emp18] = data[FamilyDetailAge.emp];
        // }

        // delete data[FamilyDetailAge.emp];
        return data;

    }, []);

    console.log(updatedFamilyData, 'updated data');

    return <Dialog
        PaperProps={{
            sx: { boxShadow: '0px 4px 14px 4px #0000001A' }
        }}
        open={true} fullWidth={true}
        onClose={() => {
            props.toggle()
        }}

    >
        <DialogTitle sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200] ?? 'grey'}` }}>
            <Unstable_Grid2 container>
                <Unstable_Grid2 xs={11}><Typography variant="subtitle1" style={{ ...boldText }} >Sum Insured & Demographics</Typography></Unstable_Grid2>
                <Unstable_Grid2 sx={{ display: 'flex', justifyContent: 'end' }} xs={1}>
                    <Icon icon={'mdi:close'} style={{ cursor: "pointer" }} onClick={props.toggle}></Icon>
                </Unstable_Grid2>
            </Unstable_Grid2>

        </DialogTitle>
        <DialogContent >
            <Unstable_Grid2 marginTop={'10px'} spacing={2} container>
                <Unstable_Grid2 sx={{ display: 'flex', flexDirection: 'column' }} sm={6} xs={6} md={3}><DataLabel label={'Sum Insured'} value={props.sumInsured}></DataLabel></Unstable_Grid2>

                <Unstable_Grid2 sx={{ display: 'flex', flexDirection: 'column' }} sm={6} xs={6} md={3}><DataLabel label={'Plan Type'} value={props.planType}></DataLabel></Unstable_Grid2>
            </Unstable_Grid2>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', margin: '10px 0' }}>
                <Typography variant="subtitle2">Insured Details</Typography>
                <div style={{ flexGrow: '1' }}>
                    <hr />
                </div>
            </div>

            <Unstable_Grid2 container spacing={3} >
                {
                    Object.keys(updatedFamilyData ?? {}).map(key => {
                        const val = updatedFamilyData[key];
                        return <Unstable_Grid2 key={key} sx={{ display: 'flex', flexDirection: 'column' }} sm={6} xs={6} md={4}><DataLabel label={FamilyDetailAgeLabel[key] ?? ''} value={val}></DataLabel></Unstable_Grid2>
                    })
                }
            </Unstable_Grid2>

        </DialogContent>

    </Dialog>
}

const QuoteGeneratedUI = (props: { calculated: CalculatedPremiumType, quoteDownloaded: string[] }) => {

    return <Unstable_Grid2 sx={{ display: 'flex', flexDirection: 'column' }} xs={6} sm={6} md={'auto'}><DataLabel label={'Quote Generated'} value={
        <>
            {/* <div><span style={{ fontWeight: 100 }}>Base</span> : {!!props?.calculated?.basePremium && <span>&#8377;{props?.calculated?.basePremium + '/yr' ?? '-'}</span>}</div>
            <div><span style={{ fontWeight: 100 }}>Base + Maternity</span> : { !!props?.calculated?.maternityPremium && <span>&#8377;{props?.calculated?.maternityPremium + '/yr' ?? '-'}</span>}</div> */}
            <div><span style={{ fontWeight: 100 }}>Customized</span> : {!!props?.calculated?.customizedPremium && <span>&#8377;{props?.calculated?.customizedPremium + '/yr' ?? '-'}</span>}</div>
        </>
    }></DataLabel></Unstable_Grid2>

}

const SumInsuredUI = (props: { sum: string[], familyDetails: FamilyDetailsType, planType: string }) => {

    const [familyDetailsToBeShow, setFamilyDetailsToBeShow] = useState<{ sum: string, details: { [key: string]: string } } | null>(null)

    const onEyeClick = (sum: string) => {
        const details = props.familyDetails[sum];
        setFamilyDetailsToBeShow({ details, sum })

    }

    const closeFamilyDetailModal = () => {
        setFamilyDetailsToBeShow(null)
    }

    return <>
        <Unstable_Grid2 sx={{ display: 'flex', flexDirection: 'column' }} xs={'auto'}>
            <DataLabel label={'Sum Insured'} value={<>
                {props.sum.map((sum, index) => {
                    const showEye = Object.keys(props?.familyDetails ?? {}).includes(sum);
                    return <div key={sum} style={{ display: 'flex' }} >
                        <span style={{ fontWeight: 100, color: '#000000', display: 'flex' }}>Sum Insured {index + 1} </span>
                        &nbsp;:&nbsp;
                        <span style={{ display: 'flex', alignItems: 'center' }} >
                            <span > {sum} </span> &nbsp;
                            {showEye && <span style={{ display: 'flex', alignItems: 'center' }}><Icon icon="mdi:eye-outline" onClick={() => onEyeClick(sum)} cursor={'pointer'} color={DASBHBOARD_BLUE_COLOR} height={'20px'} width={'20px'} /></span>}

                        </span>
                    </div>

                })}
            </>
            }>
            </DataLabel></Unstable_Grid2>
        {familyDetailsToBeShow && <FamilyDetailModal detail={familyDetailsToBeShow.details} planType={props.planType} sumInsured={familyDetailsToBeShow.sum} toggle={closeFamilyDetailModal} />}
    </>

}

type DocumentListPropsType = {
    leadId: number | string,
    toggle: (t: boolean) => void,
    companyId: string
}

export type UploadMarketRenewalDocRequest = {
    documentImage: string
} & MarketRenewalDocType;

const DocumentList = (props: DocumentListPropsType) => {



    const [data, setDocList] = useState<MarketRenewalDocType[] | null>(null);

    const {addAlert}=useSnackBars() || {};

    const [uploadDocuments, setUploadDocuments] = useState<MarketRenewalDocType | null>(null);

    const voAuthToken = getCookie('vo-auth-token')
    const agent = getCookie('vo-login-id');


    const toggleUploadDocuments = (data?: MarketRenewalDocType) => {
        if (data?.category) {
            setUploadDocuments(data);
        } else {
            setUploadDocuments(null);
        }
    }

    const fetchDocument = (leadID: number | string) => {
        WebloaderTS("show")
        const pro = LeadApiService.getDocumentList(leadID, voAuthToken, agent);
        pro.then(res => {
            if (res.data?.data?.documents?.length) {
                setDocList(res.data.data.documents);
            } else {
                setDocList(null);
            }
            WebloaderTS()

        }).catch(error => {
            WebloaderTS();
            if(error.response?.status ===500 && error.response?.data?.message){
                addAlert(error.response.data.message);
            }
            if (error.response && error.response.status === 401) {
                console.error('Bad Request:', error.response.data);
            }
        });
    }

    useEffect(() => {
        fetchDocument(props.leadId)
    }, [props.leadId]);

    const uploadFileApi = (req: UploadMarketRenewalDocRequest) => {
        WebloaderTS("show")
        const pro = LeadApiService.uploadCategoryDocument(req, props.leadId, props.companyId, voAuthToken, agent);
        pro.then(res => {
            console.log('Upload finished ', res);
            if (req?.category && res.data?.data?.marketRenewalDoc[req.category]?.isUploaded) {
                addAlert('Document uploaded successfully');
            } else {
                addAlert('Failed to upload document. Please try again');
            }
            

            WebloaderTS()
        }).catch(error => {
            WebloaderTS();
           // if (error.response && error.response.status === 401) {
                console.error('Bad Request:', error.response.data);
                addAlert('Failed to upload document. Please try again');
           // }
        }).finally(() => {
            console.log('Upload finished finally');
            toggleUploadDocuments();
            fetchDocument(props.leadId);
        });
    }

    const onUpoad = (files: File[]) => {
        if (files.length > 0) {
            const singleFile = files[0];
            console.log('Upload', singleFile);
            toBase64(singleFile).then((file: string) => {
                if (uploadDocuments?.category) {
                    const f = file.split(',')[1] ?? '';
                    const req: UploadMarketRenewalDocRequest = { category: uploadDocuments?.category ?? '', displayName: singleFile.name, documentType: UploadedFileType[singleFile.type as string], documentImage: f }
                    uploadFileApi(req);
                }
            });
        }
    }

    const onDownloadFiles = async (file?: string, name?:string) => {
        if (file) {
            try{
                let url=file;
                if(!url.startsWith('http')){
                    url=`https://${url}`
                }
                const response = await axios.get(url, {

                  responseType: "blob",
                });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(new Blob([response.data]));
                link.setAttribute("download", name??'');
                document.body.appendChild(link);
                link.click();
              } catch (e:any) {
                  console.log(e.response, e);
                  if(e?.response?.status === 500) {
                    addAlert('Internal Server Error (500)')
                  }
              }
        }
    }

    const deleteFile = (document: MarketRenewalDocType) => {
        WebloaderTS("show")
        if (!document.category || !document.documentId) {
            return;
        }
        const pro = LeadApiService.deleteCategoryDocument(document.category, props.leadId, document.documentId, voAuthToken, agent);
        pro.then(res => {
            WebloaderTS()
             addAlert('Document deleted successfully');
        }).catch(error => {
            WebloaderTS();
            if (error.response && error.response.status === 401) {
                console.error('Bad Request:', error.response.data);
                addAlert('Failed to delete document. Please try again');
            }
        }).finally(() => {
            console.log('delete finished');
            toggleUploadDocuments();
            fetchDocument(props.leadId);
        });

    }



    return <><Dialog
        PaperProps={{
            sx: { boxShadow: '0px 4px 14px 4px #0000001A' }
        }}

        open={true} fullWidth={true}
        onClose={() => {
            props.toggle(false)
        }}
    >

        <DialogTitle sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200] ?? 'grey'}` }}>
            <Unstable_Grid2 container>
                <Unstable_Grid2 xs={11}>
                    <Typography variant="subtitle1" fontWeight={500}>Uploaded Files</Typography>
                </Unstable_Grid2>
                <Unstable_Grid2 sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }} xs={1}>
                    <Icon icon={'mdi:close'} style={{ cursor: "pointer" }} onClick={() => props.toggle(false)}></Icon>
                </Unstable_Grid2>
            </Unstable_Grid2>

        </DialogTitle>
        <DialogContent sx={{ padding: '16px', marginTop: '16px' }}>
            {
                !!data?.length && data.map(document => {
                    return <>
                        <Unstable_Grid2 container direction={'row'} marginBottom={'5px'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="caption" alignItems={'center'} noWrap fontSize={'11px'} fontWeight={400} color={'#808080'}>{DocumentTypeLabel[document.category as DocumentType]}</Typography>
                            {!document.documentId && <Button variant="text" size="small" sx={{ fontSize: '12px', fontWeight: '600', color: '#0d6efd' }} onClick={() => toggleUploadDocuments(document)}>+ Add File</Button>}
                        </Unstable_Grid2>
                        {!!document?.documentId && <Card sx={{ margin: '0px 0px 8px 0px ', border: '1px solid #E6E6E6', background: '#FBFDFF', borderRadius: '8px' }}>
                            <CardContent >

                                <> <Unstable_Grid2 container spacing={4} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Unstable_Grid2 xs={8} sm={8} md={10}><Typography variant="body2" noWrap fontSize={'14px'} fontWeight={600} color={'#000000'}>{document.displayName}</Typography></Unstable_Grid2>
                                    <Unstable_Grid2 xs={4} sm={4} md={2} sx={{ display: 'flex', justifyContent: 'end', gap: '16px' }}>
                                        <Unstable_Grid2 display={'flex'} alignItems={'center'} md={4}><Icon onClick={() => onDownloadFiles(document.presignedUrl)} fontSize={'16px'} icon={'mdi:tray-download'} style={{ cursor: "pointer", color: '#0d6efd' }} ></Icon></Unstable_Grid2>
                                        <Unstable_Grid2 display={'flex'} alignItems={'center'} md={4}><Icon fontSize={'16px'} onClick={() => deleteFile(document)} icon={'mdi:trash-can-outline'} color=" #FF0000" style={{ cursor: "pointer" }} ></Icon></Unstable_Grid2>
                                    </Unstable_Grid2>
                                </Unstable_Grid2>


                                    <Typography variant="caption" noWrap fontSize={'11px'} fontWeight={400} color={'#808080'}>{document.documentType}</Typography></>
                            </CardContent>

                        </Card>}
                    </>
                })
            }
            {
                uploadDocuments?.category && <Dialog
                    PaperProps={{
                        sx: { boxShadow: '0px 4px 14px 4px #0000001A' }
                    }}

                    open={true} fullWidth={true}
                    onClose={() => {
                        toggleUploadDocuments()
                    }}
                >

                    <DialogTitle sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200] ?? 'grey'}` }}>
                        <Unstable_Grid2 container>
                            <Unstable_Grid2 xs={11}>
                                <Typography variant="subtitle1" fontWeight={500}>Upload {DocumentTypeLabel[uploadDocuments.category as DocumentType]}</Typography>
                            </Unstable_Grid2>
                            <Unstable_Grid2 sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }} xs={1}>
                                <Icon icon={'mdi:close'} style={{ cursor: "pointer" }} onClick={() => toggleUploadDocuments()}></Icon>
                            </Unstable_Grid2>
                        </Unstable_Grid2>
                    </DialogTitle>
                    <DialogContent sx={{ padding: '18px', marginTop: '18px' }}>
                        <DynamicFileUploader typeAllowed={DocumentType.claimPdf === uploadDocuments.category ? { "application/pdf": [".pdf"] } : {
                            "application/pdf": [".pdf"],
                            "application/msexcel": [".xls", ".xlsx"]
                        }} maxFiles={1} onUpload={onUpoad} maxFileSizeInBytes={7340032} onCloce={toggleUploadDocuments} />
                    </DialogContent>
                </Dialog>
            }
        </DialogContent>


    </Dialog>
    </>
}




const LeadCard = (props: LeadCardPropTypes) => {
    const dateFormat = dateFormatMMMDDYYYY(props.createdAt);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [showDocumentList, setShowDocumentLIst] = useState<boolean>(false);

    const toggleDocumentList = (data: boolean) => {
        setShowDocumentLIst(data);
    }

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return <Card sx={{ margin: '10px 0', width: '100%', borderRadius: '14px', boxShadow: '0px 2px 20px 0px #0000000D', border: '1px solid #E6E6E6' }}>
        <CardHeader
            sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[200] ?? 'grey'}`, padding: '16px 24px' }}
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Typography variant="h6" className="h6" fontWeight={'600'} >
                            {props.corporateName}
                        </Typography>
                        <Typography variant="subtitle2" className="subtitle2 text-muted">
                            {props.userType}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '10px' }}>
                            <SmeStatusBadges badges={LeadStatusBadge} selectedKey={props.leadStatus} />
                        </div>

                        {
                            props.policyType === PolicyType.MARKET_RENEWAL && <IconButton
                                onClick={() => toggleDocumentList(true)}
                                sx={{
                                    '&:focus': {
                                        outline: 'none'
                                    }
                                }}
                                aria-label="more"
                            >
                                <Icon icon="mdi:cloud-upload-outline" />
                            </IconButton>
                        }
                        {/* {
                            props.policyType !== PolicyType.MARKET_RENEWAL && <IconButton
                                sx={{
                                    '&:focus': {
                                        outline: 'none'
                                    }
                                }}
                                aria-label="more"
                            >
                                <Icon icon={'mdi:edit-outline'} />
                            </IconButton>
                        } */}
                        {/* {props.policyType === PolicyType.MARKET_RENEWAL && <><IconButton
                            sx={{
                                '&:focus': {
                                    outline: 'none'
                                }
                            }}
                            aria-label="more"
                            aria-controls="dropdown-dashboard-lead-action"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuClick(e)}
                        >
                            <Icon icon={'mdi:dots-vertical'} />
                        </IconButton>
                            <Menu
                                id="dropdown-dashboard-lead-action"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {/* <MenuItem onClick={() => console.log('Edit clicked')}>Edit</MenuItem> */}
                        {/* {props.policyType === PolicyType.MARKET_RENEWAL && <MenuItem onClick={() => toggleDocumentList(true)}>View Upload Files</MenuItem>}

                            </Menu></>}  */}
                    </div>
                </div>
            }
        />
        <CardContent sx={{ p: '16px', '&:last-child': { pb: '16px' } }} >
            <Unstable_Grid2 container spacing={2} direction={'row'} sx={{ position: 'relative' }}>
                <Unstable_Grid2 sx={{ display: 'flex', flexDirection: 'column' }} sm={6} xs={6} md={2}>
                    <DataLabel label={'Contact Person Name'} value={props.contactName} /></Unstable_Grid2>
                <Unstable_Grid2 sx={{ display: 'flex', flexDirection: 'column' }} sm={6} xs={6} md={2}>
                    <DataLabel label={'Contact Person Phone'} value={props.contactNumber} /></Unstable_Grid2>
                <Unstable_Grid2 sx={{ display: 'flex', flexDirection: 'column' }} sm={6} xs={6} md={3}>
                    <DataLabel label={'Contact Person email'} value={props.emailId} /></Unstable_Grid2>
                <Unstable_Grid2 sx={{ display: 'flex', flexDirection: 'column' }} sm={6} xs={6} md={2}>
                    <DataLabel label={'Quote Type'} value={PolicyTypeLabel[props?.policyType]} /></Unstable_Grid2>
                <QuoteGeneratedUI calculated={props.calculatedOutput} quoteDownloaded={props.quoteGenerated}></QuoteGeneratedUI>
                <SumInsuredUI sum={props.sumInsured} familyDetails={props.familyDetails} planType={props.planType} />
                <Unstable_Grid2 sx={{ display: 'flex', flexDirection: 'column' }} sm={6} xs={6} md={2}>
                    <DataLabel label={'Policy Type'} value={props.planType} />


                </Unstable_Grid2>
                <Typography variant="subtitle2" className="d-flex position-absolute " style={{ bottom: '8px', right: '8px', fontWeight: '500', color: '#A3A3A3' }}>
                    {dateFormat}
                </Typography>
            </Unstable_Grid2>
        </CardContent>
        {
            showDocumentList && <DocumentList companyId={props.companyId} leadId={props.leadId} toggle={toggleDocumentList} />
        }
    </Card>
}
export default LeadCard;
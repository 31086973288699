import { Snackbar } from "@mui/material";
import React from "react";

const SmeSnackbar=({open,message, handleClose})=>{
    return  <Snackbar
    anchorOrigin={{  vertical: 'top',
        horizontal: 'center', }}
    open={open}
    onClose={handleClose}
    message={message}
    key={'top'+'center'}
    autoHideDuration={6000}
    variant="filled"
  />
}

export default SmeSnackbar;
import { Modal } from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import './modals.css'
import React from "react";
import { removeSSOCookies } from "../../utils";

const SessionExpired = () => {
    return <Modal open={true} showCloseIcon={false} closeOnOverlayClick={false} closeOnEsc={false} center blockScroll={true}      
        classNames={{
        overlay: 'sessionOverlay',
        modal: 'sessionModal',
      }}>
        <div style={{
            textAlign:'center'
        }}>
            <img alt="session expired" src="/images/sessionExpired.svg" />
            <div style={{fontSize:'18px',fontWeight:'600',padding:'10px 0px'}}>Session Expired</div>
            <div style={{padding:'10px 0px 30px 0px', color:'#808080',fontSize:'14px'}}>Your session has been expired. Please login again.</div>
            <button
                style={{
                    color:'#fff',
                    backgroundColor:'#36498A',
                    padding:'4px 14px',
                    borderRadius:'8px',
                    fontWeight:'600',
                    border: "2px solid rgb(54, 73, 138)",
                    cursor:'pointer'
                }}
                onClick={()=>{
                    removeSSOCookies()
                    window.location.replace(`${process.env.REACT_APP_VO_URL}login`)
                }}
            >
                OK
             </button>
        </div>
    </Modal>
}

export default SessionExpired
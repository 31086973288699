import TextField  from "@mui/material/TextField";
import { Field } from "formik";
import React from "react";

const NumberInput = ({ name, placeholder, maxlen ,disabled=false, className='form-control',required=false,currentValue}) => {
  const numbersOnlyHandler = (e, callBack) => {
    const re = /^[0-9]+$/;
    if (!(e.target.value === "" || re.test(e.target.value))) {
      e.preventDefault();
      return;
    }
    else{
      callBack(e);
    }
    
  };

  return (
    <Field name={name}>
      {({ field }) => (
        <TextField
          type="text"
          label={placeholder}
          disabled={disabled}
          className={className}
          placeholder={placeholder}
          maxLength={maxlen ? maxlen : ""}
           {...field}
          value={currentValue}
          onChange={(e) => numbersOnlyHandler(e, field.onChange)}
          onFocus={(e) => {
            // Ensure initial value is validated
            numbersOnlyHandler(e, field.onChange);
          }}
          required={required}
        />
      )}
    </Field>
  );
};

export default NumberInput;

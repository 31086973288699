export const branch = {
  Chennai: [
    "ZO-Chennai-110000",
    "AO-Chennai-111100",
    "BO-AnnaNagar-111111",
    "BO-T.Nagar-111113",
    "BO-Kilpauk-111114",
    "BO-Ambattur-111115",
    "BO-Kodambakkam-111116",
    "BO-Pallavaram-111117",
    "BO-Tambaram-111118",
    "BO-Adayar-111120",
    "AO-Parry's-111121",
    "BO-Mylapore-111122",
    "BO-Velachery-111123",
    "BO-DirectAgent-111124",
    "BO-Perambur-111125",
    "BO-Kancheepuram-111127",
    "BO-T.Nagar-II-111128",
    "BO-Valasarawakkam-111129",
    "BO-Saidapet-111130",
    "BO-Perungudi-111131",
    "BO-Tindivanam-111132",
    "BO-Karaikal-111133",
    "BO-Pondichery-111200",
    "BO-Villupuram-111211",
    "BO-Cuddalore-111214",
    "BO-Vellore-111215",
    "BO-Thiruvanamalai-111217",
    "BO-Ambur-111218",
    "RBO-Kallakurichi-611001",
    "BO-PortBlair-111219",
    "BO-Thiruvallur-111136",
    "TeleSales-700001",
    "TeleSalesUnit-II-700011",
  ],
  Coimbatore: [
    "ZO-Coimbatore-120000",
    "BO-SaiBabaClnyCoimbatore-121111",
    "BO-Gandhipuram-121112",
    "BO-Mettupalayam-121114",
    "BO-Sungam-121118",
    "BO-DirectAgent-121119",
    "Bo-Pollachi-121120",
    "BO-Avinashi-121121",
    "BO-Coonoor-121123",
    "BO-Coimbatore(RSPuram)-121126",
    "BO-Sulur-121128",
    "B0-Udumalpet-121129",
    "BO-Tiruppur-121513",
    "BO-TiruppurII-121517",
    "BO-Dharapuram-121519",
    "RBO-Annur-612014",
  ],
  Salem: [
    "BO-Perundurai-121115",
    "BO-Gobichettipalayam-121113",
    "BO-Erode-121116",
    "BO-Erode-II-121122",
    "BO-Bhavani-121124",
    "BO-Tiruchengode-121125",
    "BO-Attur-121127",
    "BO-Satyamangalam-121130",
    "BO-Salem-I-121500",
    "BO-Dharmapuri-121511",
    "BO-Nammakal-121514",
    "BO-Hosur-121515",
    "BO-Salem-121516",
    "BO-Kangeyam-121518",
    "BO-Krishnagiri-121520",
    "RBO-Harur-612013",
    "RBO-Mettur-612015",
    "RBO-Rasipuram-612016",
    "RBO-Anthiyur(Rural)-612017",
    "RBO-Edappadi -612019",
  ],
  Mumbai: [
    "BO-Panaji-151123",
    "ZO-Mumbai-170000",
    "AO-Mumbai(AndheriI)-171100",
    "BO-FortBranch-171111",
    "BO-NaviMumbai(Vashi)-171112",
    "BO-Borivali-171113",
    "BO-Dadar-171114",
    "BO-Thane-171115",
    "BO-Ghatkopar-171116",
    "BO-Dombivali-171117",
    "BO-Vasai-171118",
    "BO-Goregaon-171119",
    "BO-MiraRoad-171120",
    "BO-Ullasnagar-171121",
    "BO-ThaneII-171122",
    "BO-DadarII-171123",
    "BO-AndheriII-171124",
    "BO-Boisar-171125",
    "BO-Panvel-171126",
    "BO-BorivaliEast-171127",
    "BO-AndheriIII-171128",
    "BO-FortI-171129",
    "BO-NaviMumbai(VashiI)-171130",
    "BO-BorivaliII-171131",
    "BO-DadarIII-171132",
    "BO-ThaneIII-171133",
    "BO-GhatkoparI-171134",
    "BO-MiraRoadI-171135",
    "BO-ThaneIV-171136",
    "BO-Bandup-171137",
    "BO-Margao(Madagoan)-171138",
    "BO-Andheri-IV-171139",
    "BO-Fort-II-171140",
    "BO-Dadar-IV-171141",
    "BO-Thane-V-171142",
    "BO-Ghatkopar-II-171143",
    "BO-Dombivali-I-171144",
    "BO-Dombivali-II-171145",
    "BO-Vasai-I-171146",
    "BO-Goregaon-I-171147",
    "BO-Ullasnagar-II-171148",
    "BO-BorivaliEast-I-171149",
    "BO-Virar-171150",
    "BO-Chinchpokli-171151",
    "BO-Kudal-171152",
    "BO-Chembur-171153",
    "BO-Santacruz-171154",
    "Telesales-Mumbai-700017",
  ],
  Trichy: [
    "ZO-Trichy-121200",
    "BO-Thanjavur-121212",
    "BO-Trichy(Thillainagar)-121214",
    "BO-Kumbakonam-121215",
    "BO-Srirangam-121216",
    "BO-Mayiladuthurai-121220",
    "BO-Mannargudi-121221",
    "BO-Thiruverambur-121222",
    "BO-Ariyalur-121223",
    "BO-Thuraiyur-121224",
    "BO-Kulithalai-121225",
    "BO-Pudukottai-121323",
    "BO-Karur-121512",
    "RBO-Perambalur-612003",
    "RBO-Manaparai-612004",
    "RBO-Tiruvarur-612005",
    "RBO-Nagapattinam-612010",
    "RBO-Orathanadu-612011",
  ],
  Tirupathi: [
    "BO-Tirupathi-111119",
    "BO-Nellore-131125",
    "BO-Kurnool-131129",
    "BO-Cuddapah-131216",
    "BO-Ongole-131217",
    "AO-Tirupathi-131500",
    "BO-Hindhupur-131510",
    "BO-Chittoor-131511",
    "BO-Prodattur-131512",
    "BO-Kandukur-131513",
    "BO-Ananthpur-141122",
    "RBO-Madanapalle-613001",
    "RBO-Markapuram-613002",
    "RBO-Nandyal-613007",
    "RBO-Kavali-613009",
    "RBO-Rayadurgam-613014",
  ],
  Pune: [
    "ZO-Pune-150000",
    "AO-Pune-151100",
    "BO-Pune-151111",
    "BO-Pune-II-151112",
    "BO-Nashik-151113",
    "BO-AhmedNagar-151114",
    "BO-Aurangabad-151115",
    "BO-Nagpur-151116",
    "BO-Kolhapur-151117",
    "BO-Pimpri-151118",
    "BO-Hadapsar-151119",
    "BO-Nagpur-II-151120",
    "BO-Nashik-II-151121",
    "BO-Jalgaon-151122",
    "BO-Nigdi-151124",
    "BO-Solapur-151125",
    "BO-Amravathi-151126",
    "BO-Nanded-151127",
    "BO-Chakan-151128",
    "BO-Ratnagiri-151129",
    "BO-Baramati-151130",
    "BO-Beed-151131",
    "BO-Dhule-151132",
    "BO-Bhandara-151133",
    "BO-Satara-151134",
    "BO-Nagpur-III-151135",
    "BO-Daund-151136",
    "BO-Sangli-151137",
    "BO-Nandurbar-151138",
    "BO-Chandrapur-151139",
    "BO-Latur-151140",
    "BO-Sangamner-151141",
    "RBO-Chiplun-615001",
    "RBO-DeulgaonRaja-615002",
    "RBO-Pandharpur-615003",
    "RBO-Raver-615004",
    "RBO-Sakri-615005",
    "RBO-Talegaon-615006",
    "RBO-Lasalgaon-615007",
    "RBO-Warud-615008",
    "RBO-Paratwada-615009",
    "RBO-Yavatmal-615010",
    "RBO-Sillod-615011",
    "RBO-Chalisgaon-615012",
    "RBO-Gondia-615013",
    "RBO-Shirpur-615014",
    "RBO-Gangakhed-615015",
    "RBO-Udgir-615016",
    "RBO-Malegaon-615017",
    "BO-Narayangaon-151142",
    "BO-Nashik-III-151143",
    "BO-Chinchwad-151144",
    "RBO-Khamgaon-615018",
    "RBO-Hingoli-615019",
    "RBO-Washim-615023",
    "RBO-Karad-615020",
    "RBO-Shirur-615021",
  ],
  PatnaAO: [
    "BO-Patna-231114",
    "BO-PatnaII-231213",
    "BO-Gaya-231214",
    "BO-Bhagalpur-231216",
    "BO-Muzaffarpur-231218",
    "AO-Patna-231500",
    "RBO-Hajipur-623012",
  ],
  OnlineBusiness: ["WebOnlinebusiness-700002"],
  Odisha: [
    "AO-Bhubaneswar-191200",
    "BO-Bhubaneswar-191211",
    "BO-Cuttack-191212",
    "BO-Balasore-191213",
    "BO-Sambalpur-191214",
    "BO-Rourkela-191215",
    "BO-Baripada-191216",
    "BO-Berhampur-191217",
    "BO-Puri-191218",
    "BO-Nayapalli-191219",
    "RBO-Bhawanipatna-619001",
    "RBO-Angul-619002",
    "RBO-Jajpur-619003",
    "RBO-Kendrapara-619004",
    "RBO-Jagatsinghpur-619005",
    "RBO-Balangir-619006",
    "RBO-Jharsuguda-619007",
    "RBO-Bargarh-619009",
    "RBO-Bhadrak-619010",
    "RBO-Keonjhar-619016",
    "RBO-Rayagada-619027",
    "RBO-Nayagarh-619028",
    "RBO-Dhenkanal-619036",
    "RBO-Sundargarh-619037",
    "RBO-Sonepur-619038",
  ],
  Madurai: [
    "ZO-Madurai-121300",
    "BO-Madurai-II-121311",
    "BO-Tallakulam-121312",
    "BO-Virudunagar-121313",
    "BO-Ramanathapuram-121314",
    "BO-Dindigul-121316",
    "BO-Theni-121317",
    "BO-DirectAgent-121318",
    "BO-AnnaNagar-121319",
    "BO-Marthandam-121320",
    "BO-Thuckalay-121321",
    "BO-Sivakasi-121322",
    "BO-Palani-121324",
    "BO-Thirunagar-121325",
    "BO-Tirunelveli-121400",
    "BO-Tuticorin-121411",
    "BO-Nagercoil-181114",
    "RBO-Arupukottai-612001",
    "RBO-Oddanchatram-612002",
    "RBO-Karaikudi-612006",
    "RBO-Batlagundu-612007",
    "RBO-Cumbum-612008",
    "RBO-Kovilpatti-612009",
    "RBO-Tenkasi-612012",
    "RBO-Paramakudi-612018",
    "Telesales-Maduari-700023",
  ],
  Ludhiana: [
    "BO-Ludhiana-161114",
    "BO-Jallandhar-161125",
    "BO-Amritsar-211111",
    "BO-Jammu-211113",
    "ZO-Ludhiana-211200",
    "BO-Khanna-211211",
    "BO-Pathankot-211212",
    "BO-Hoshiarpur-211213",
    "BO-Amritsar-II-211214",
    "BO-JallandharII-211215",
    "BO-Jammu-II-211216",
    "BO-Bathinda-211217",
    "BO-Ludhiana-II-211218",
    "BO-Moga-211222",
    "BO-Sangrur-211223",
    "BO-Ludhiana-III-211224",
    "BO-TarnTaran-211226",
    "BO-Una-211227",
    "BO-Jagraon-211228",
    "BO-Barnala-211229",
    "RBO-Mansa-616001",
    "RBO-Fazilka-621001",
    "RBO-Gharshankar-621002",
    "RBO-Sunam-621003",
    "RBO-Nangal-621004",
    "BO-Morinda-211230",
    "BO-Muktsar-211231",
    "BO-Bathinda-II-211232",
    "BO-Batala-211233",
    "BO-Amritsar-III-211234",
    "BO-Ludhiana-IV-211236",
    "BO-Jammu-III-211235",
    "BO-Jallandhar-III-211237",
    "BO-Kathua-211238",
    "RBO-Sirhind-621008",
    "RBO-Nakodar-621011",
    "RBO-Malerkotla-621012",
    "RBO-Dasuya-621018",
  ],
  LucknowUP: [
    "BO-Lucknow-161119",
    "BO-Kanpur-161122",
    "UP-ZO-230000",
    "AO-Lucknow-231100",
    "BO-Allahabadchanged-231111",
    "BO-Agra-231115",
    "BO-Bareilly-231116",
    "BO-Varanasi-231117",
    "BO-Jhansi-231118",
    "BO-Moradabad-231119",
    "BO-Aligarh-231120",
    "BO-Kanpur-II-231121",
    "BO-Gorakhpur(Prop)-231122",
    "BO-Mathura-231123",
    "BO-Azamgarh-231124",
    "BO-Faizabad-231125",
    "BO-Farrukhabad-231126",
    "BO-Ballia-231127",
    "BO-Shahjahanpur-231128",
    "AO-Agra-231300",
    "BO-Firozabad-231311",
    "BO-Orai-231312",
    "BO-Bulandshahar-231313",
    "AO-Varanasi-231400",
    "BO-Jaunpur-231401",
    "RBO-Robertsganj-623003",
    "RBO-Chitrakoot-623006",
    "RBO-Hathras-623005",
    "RBO-Sultanpur-623007",
    "RBO-Pratapgarh-623008",
    "RBO-Raebareli-623009",
    "RBO-Fatehpur-623010",
    "RBO-Etawah-623011",
    "RBO-Gonda-623013",
    "RBO-Chandausi-623015",
    "Telesales-Lucknow-700020",
  ],
  Kolkata: [
    "ZO-Kolkata-190000",
    "BO-NorthKolkata-191111",
    "BO-SouthKolkata-191112",
    "BO-Howrah-191113",
    "BO-Durgapur-191114",
    "BO-CentralKolkata-191116",
    "BO-Behala-191117",
    "BO-SaltLakeCity-191119",
    "BO-Serampore-191120",
    "BO-Barasat-191121",
    "BO-Asansol-191122",
    "BO-Burdwan-191123",
    "BO-Siliguri-191124",
    "BO-Garia(DAB)-191125",
    "BO-Baguihati-191126",
    "BO-Midnapore-191127",
    "BO-Siliguri-II-191128",
    "BO-Bolpur-191129",
    "BO-Dankuni-191130",
    "BO-Jalpaiguri-191131",
    "BO-Kalyani-191132",
    "BO-NewAlipore-191133",
    "Bo-Malda-191134",
    "BO-Kankurgachi-191135",
    "BO-Bankura-191136",
    "BO-Cooch-Behar-191137",
    "BO-CBDKolkata-191138",
    "BO-Alipurduar-191139",
    "BO-Balurghat-191140",
    "BO-Raiganj-191141",
    "BO-Chinsurah-191142",
    "BO-Tarakeshwar-191143",
    "BO-Darjeeling-191144",
    "BO-Purulia-191145",
    "BO-Contai-191146",
    "BO-Barrackpore-191147",
    "BO-Gangtok-191148",
    "BO-KolkataII-191149",
    "BO-NorthKolkata-II-191150",
    "BO-Serampore-II-191151",
    "BO-Kasba-191152",
    "RBO-Baruipur-619008",
    "RBO-Habra-619011",
    "RBO-Ghatal-619012",
    "RBO-Berhampore-619013",
    "RBO-Krishnanagar-619014",
    "RBO-Uluberia-619015",
    "RBO-Raghunathpur-619017",
    "RBO-MalBazaar-619018",
    "RBO-Tamluk-619019",
    "RBO-Bagdogra-619020",
    "RBO-DiamondHarbour-619021",
    "RBO-Suri-619022",
    "RBO-Raniganj-619023",
    "RBO-Domjur-619024",
    "RBO-Katwa-619025",
    "RBO-Bethudahari-619026",
    "RBO-Islampur-619029",
    "RBO-Jhargram-619030",
    "RBO-Kalna-619031",
    "RBO-Kandi-619032",
    "RBO-Rampurhat-619033",
    "RBO-Barakar-619034",
    "RBO-Andul-619035",
    "Telesales-Kolkata-700018",
  ],
  Kerala: [
    "ZO-Trivandrum-180000",
    "AO-Trivandrum-181100",
    "BO-Trivandrum-181111",
    "BO-Kollam-181112",
    "BO-Kazhakuttam-181115",
    "BO-Neyyattinkara-181116",
    "BO-Varkala-181117",
    "BO-Peroorkada-181118",
    "BO-Attingal-181119",
    "BO-Pettah-181120",
    "BO-Thiruvallam-181121",
    "BO-Karunagappalli-181122",
    "BO-Sasthamangalam-181123",
    "BO-Pothencode-181124",
    "BO-Thirumala-181125",
    "BO-DirectAgents-181126",
    "BO-Kilimanoor-181127",
    "BO-Nalanchira-181128",
    "BO-Kundara-181129",
    "BO-Karamana-181131",
    "BO-Nedumangadu-181132",
    "BO-Ramankulangara-181133",
    "BO-Pathanamthitta-181134",
    "BO-Balaramapuram-181135",
    "BO-Kattakada-181136",
    "BO-Parippally-181137",
    "BO-Kottayam-181113",
    "AO-Ernakulam-181200",
    "BO-Ernakulam-181211",
    "BO-Allepey-181212",
    "BO-Trichur-181213",
    "BO-Palakkad-181214",
    "BO-Kakkanad-181215",
    "BO-Patturaickal-181216",
    "BO-Chalakudi-181217",
    "BO-Ottapalam-181218",
    "BO-Perumbavoor-181219",
    "BO-Kunnamkulam-181220",
    "BO-Ponkunnam-181221",
    "BO-Aluva-181222",
    "BO-Haripad-181223",
    "BO-EastFort-Thrissur-181224",
    "BO-Thiruvalla-181225",
    "BO-Chittur-181226",
    "BO-Edappally-181227",
    "BO-MGRoad-181228",
    "ZO-Calicut-181300",
    "BO-Calicut-181311",
    "BO-Malapuram-181312",
    "BO-Kannur-181313",
    "BO-Kalpetta-181314",
    "BO-SulthanBathery-181315",
    "BO-MavoorRoad-181316",
    "BO-Mananthavady-181318",
    "BO-WestNadakavu-181319",
    "BO-KallaiRoad-181320",
    "BO-Kuttiady-181321",
    "BO-Thaliparamba-181322",
    "BO-Kottakal-181323",
    "BO-Kasaragod-181325",
  ],
  Jaipur: [
    "BO-Gwalior-161123",
    "BO-Jaipur-161120",
    "BO-Udaipur-161126",
    "ZO-RAJASTHAN-220000",
    "ZO-Jaipur-221100",
    "BO-Kota-221111",
    "BO-Jodhpur-221112",
    "BO-Ajmer-221113",
    "BO-Bhilwara-221114",
    "BO-Alwar-221115",
    "BO-Bikaner-221116",
    "BO-Shriganganagar-221117",
    "BO-Bhiwadi-221118",
    "BO-Kishangarh-221119",
    "BO-Pali-221120",
    "BO-Sikar-221121",
    "BO-JaipurI-221122",
    "BO-Banswara-221123",
    "BO-Morena-221124",
    "BO-Sirohi-221125",
    "BO-Beawar-221126",
    "RBO-Bheror-622001",
    "RBO-Sawaimadhopur-622002",
    "RBO-Tonk-622003",
    "RBO-Balotra-622004",
    "RBO-Kotputli-622005",
    "RRBO-Bijoliya-622006",
    "RBO-Dausa-622007",
    "RBO-Rajsamand-622008",
    "RBO-Phalodi-622009",
    "RBO-Dholpur-622011",
    "RBO-Kuchamancity-622012",
    "BO-Churu-622013",
    "RBO-Baran-622014",
    "RBO-Bharatpur-622015",
    "RBO-Bundi-622016",
    "RBO-Barmer-622017",
    "BO-Hanumangarh-221127",
  ],
  Hyderabad: [
    "ZO-Hyderabad-130000",
    "AO-Hyderabad-131100",
    "BO-Tadbund-131112",
    "BO-kukatpally-131115",
    "BO-Tarnaka-131116",
    "BO-CityBusinesCentre-131118",
    "BO-Rajamundhry-131124",
    "BO-BanjaraHills-131126",
    "BO-Himayatnagar-131127",
    "BO-DilsukhNagar(DAB)-131128",
    "BO-Karimnagar-131130",
    "BO-Nizamabad-131131",
    "BO-Khammam-131132",
    "BO-Nalgonda-131133",
    "BO-Bhimavaram-131134",
    "BO-Mancherial-131135",
    "BO-ASRaoNagar-131136",
    "BO-Mahabubabad-131137",
    "BO-Mahaboobnagar-131138",
    "BO-Siddipet-131139",
    "BO-Vanasthalipuram-131140",
    "BO-Adilabad-131141",
    "BO-Hitechcity(Attapur)-131142",
    "BO-SanjeevReddyNagar-131143",
    "BO-ShadNagar-131144",
    "BO-Gachibowli/Kondapur-131145",
    "AO-Vijayawada-131200",
    "BO-Vijayawada-131211",
    "BO-Machilipattnam-131212",
    "BO-Guntur-131214",
    "BO-GunturII-131218",
    "BO-Eluru-131219",
    "BO-Tenali-131220",
    "BO-Tadepalligudem-131221",
    "BO-Narasaroapet-131222",
    "BO-Jaggaiahpet-131223",
    "BO-Visakhapatnam-131300",
    "BO-Kakinada-131311",
    "BO-Warangal-131315",
    "BO-Gajuwaka-131316",
    "BO-Srikakulam-131318",
    "BO-Amalapuram-131319",
    "AO-Warangal-131400",
    "BO-Jagitial-131412",
    "RBO-Tanuku-613003",
    "RBO-Tuni-613004",
    "RBO-Kamareddy-613005",
    "RBO-Vizianagaram-613006",
    "RBO-Sangareddy-613008",
    "RBO-Janagaon-613010",
    "RBO-Mandapeta-613011",
    "RBO-Nuzividu-613012",
    "RBO-Jangareddygudem-613013",
    "RBO-Tiruvuru-613015",
    "RBO-Nirmal-613016",
    "RBO-Bodhan-613017",
    "RBO-Sathupally-613018",
    "RBO-Nagarkurnool-613019",
    "RBO-Bapatla-613020",
    "RBO-Bhadrachalam-613021",
    "RBO-Gudivada-613022",
    "RBO-Piduguralla-613023",
    "BO-Suchitra-131146",
    "RBO-Gajwel-613024",
    "RBO-Bhongir-613025",
    "BO-Attapur-613027",
    "BO-Armoor-613028",
    "BO-Malakpet-131150",
    "BO-Choutuppal-613026",
    "BO-Malkajgiri-131147",
    "RBO-Kompalli-613029",
    "RBO-Peddapally-613030",
    "RBO-Parvathipuram-613031",
    "TeleSalesHyderabad-700013",
    "TeleSales-Visakhapatnam-700022",
  ],
  Guwahati: [
    "ZO-Guwahati-191300",
    "BO-Silchar-191310",
    "BO-Guwahati-191311",
    "BO-Dibrugarh-191312",
    "BO-Agartala-191313",
    "BO-Shillong-191314",
    "BO-Sivasagar(Sibsagar)-191315",
    "BO-Jorhat-191316",
    "BO-Nagaon-191317",
    "BO-Tinsukia-191318",
    "BO-Bongaigaon-191319",
    "BO-Imphal-191320",
    "BO-Tezpur-191321",
  ],
  Delhi: [
    "ZO-Delhi-160000",
    "AO-Delhi-161100",
    "BO-EastDelhi-161111",
    "BO-Vaishali-161112",
    "BO-Gurgaon-161117",
    "BO-JanakPuri-161118",
    "BO-Noida-161121",
    "BO-JanakPuri-II-161127",
    "BO-Gurgaon-II-161128",
    "BO-ModelTown-161129",
    "BO-Gurgaon-III-161130",
    "BO-Rewari-161131",
    "BO-Ghaziabad-161132",
    "BO-LajpatNagar-161133",
    "BO-Dwarka-161134",
    "BO-Sikandrabad-161135",
    "BO-MotiNagar-161136",
    "BO-DilshadGarden-161137",
    "BO-Sohna-161138",
    "BO-NoidaExtension-161139",
    "BO-JanakpuriBOIII-161140",
    "BO-EastDelhiBOII-161141",
    "BO-PushpVihar-161142",
    "BO-Manesar-161143",
    "BOYamunaVihar-161144",
    "BO-Faridabad-161116",
    "BO-SouthDelhi-161124",
    "DelhiAOII-161200",
    "BO-Pitampura-161211",
    "BO-KarolBagh-161213",
    "BO-Pitampura-II-161214",
    "BO-Greaternoida-161216",
    "BO-NITFaridabad-161217",
    "BO-Shalimarbagh-161219",
    "BO-VasantKunj-161221",
    "BO-Alipur-161223",
    "BO-Madangir-161224",
    "TeleSalesDelhi-700004",
    "TeleSalesUnit-III-700016",
  ],
  Dehradun: [
    "BO-Dehradun-161115",
    "BO-Meerut-161212",
    "BO-Muzaffarnagar-161215",
    "BO-Rudrapur-161218",
    "BO-Meerut-II-161220",
    "BO-Shamli-161222",
    "AO-Dehradun-161300",
    "BO-Kashipur-161310",
    "BO-Haridwar-161311",
    "BO-Haldwani-161312",
    "BO-Saharanpur-161313",
    "BO-Roorkee-161314",
    "BO-Pithoragarh-161315",
    "BO-Rishikesh-161316",
    "BO-Kotdwar-161317",
  ],
  CorporateOffice: [
    "Onlinebusiness-700000",
    "TeleSalesUnit-I-700003",
    "CorporateOffice-900000",
  ],
  Chandigarh: [
    "BO-Chandigarh-161113",
    "ZO-Punjab&Haryana-210000",
    "ZO-Chandigarh-211100",
    "BO-Panipet-211112",
    "BO-Karnal-211114",
    "BO-Patiala-211115",
    "BO-Shimla-211116",
    "BO-Ambala-211117",
    "BO-Rohtak-211118",
    "BO-Hisar-211119",
    "BO-Yamuananagar-211120",
    "BO-Sirsa-211121",
    "BO-Sonipat-211122",
    "BO-Kurukshetra-211123",
    "BO-Panipet-II-211124",
    "BO-Hisar-II-211125",
    "BO-Panchkula-211126",
    "BO-Bhiwani-211128",
    "BO-Mandi-211129",
    "BO-Karnal-II-211130",
    "BO-Panipet-III-211131",
    "BO-Dharmshala-211132",
    "BO-Fatehabad-211133",
    "BO-Kaithal-211134",
    "BO-Jind-211135",
    "RBO-Narwana-616002",
    "RBO-Pehowa-616005",
    "RBO-Hansi-621005",
    "RBO-Tohana-621009",
    "RBO-Assandh-621010",
    "RBO-Dadri-616003",
    "RBO-Ellenebad-616004",
    "RBO-Narnaul-621006",
    "RBOBahadurgarh-621007",
    "RBO-Shahbad-621015",
    "RBO-Baddi-621013",
    "RBO-Rajpura-621016",
    "RBO-Ropar-621017",
    "RBO-Narayangarh-621014",
    "Telesales-Chandigarh-700025",
  ],
  Bhopal: [
    "ZO-Bhopal-200000",
    "AO-Bhopal-201100",
    "AO-Indore-201111",
    "BO-Ujjain-201112",
    "BO-Bhopal-201113",
    "BO-Indore-I(ABRoad)-201114",
    "BO-Indore-II(Janjeerwala)-201115",
    "BO-Jabalpur-201116",
    "BO-Raipur-201117",
    "BO-Ratlam-201118",
    "BO-Indore-III-201119",
    "BO-Sagar-201120",
    "BO-Satna-201121",
    "BO-Katni-201122",
    "BO-Indore-IV(DAB)-201123",
    "BO-Bhilai-201124",
    "BO-Indore-V-201125",
    "BO-Indore-VI-201126",
    "BO-Indore-VII-201127",
    "BO-Indore-VIII-201128",
    "BO-Dewas-201129",
    "BO-Khandwa-201130",
    "BO-Raipur-III-201131",
    "AO-Raipur-201300",
    "BO-Bilaspur-201311",
    "RBO-Dungargarh-620001",
    "RBO-Garoth-620002",
    "RBO-Harda-620003",
    "RBO-Sendhwa-620005",
    "RBO-Narsinghpur-620004",
    "RBO-Vidisha-620006",
    "RBO-Surajpur-620007",
    "RBO-Mandla-620008",
    "RBO-Nagda-620009",
    "RBO-Seoni-620010",
    "TeleSalesIndore-700005",
  ],
  Bangalore: [
    "ZO-Bangalore-140000",
    "AO-Bangalore-141100",
    "BO-BasavangudI-141111",
    "BO-IndiraNagar-141113",
    "BO-Malleswaram-141115",
    "BO-Mysore-141116",
    "BO-Davanagere-141118",
    "BO-Kolar-141119",
    "BO-Shimoga-141121",
    "BO-RajajiNagar-141123",
    "BO-S.R.Nagar-141124",
    "BO-Jayanagar-141125",
    "BO-Hebbal-141126",
    "BO-MysoreRoad-141127",
    "BO-Madiwala-141128",
    "BO-CorporateCell-141129",
    "BO-Gandhibazaar-141130",
    "BO-Tumkur-141131",
    "BO-Peenya-141132",
    "BO-Malleswaram-II-141133",
    "BO-RajajiNagar-II-141134",
    "BO-S.R.Nagar-II-141135",
    "BO-Madikeri-141136",
    "BO-IndiraNagar-II-141137",
    "BO-Mysore-II-141138",
    "BO-Chikmagalur-141140",
    "BO-Hebbal-II-141141",
    "BO-Indiranagar-III-141142",
    "BO-Chitradurga-141143",
    "BO-Basavangudi-II-141144",
    "BO-Jayanagar-II-141145",
    "BO-Rajajinagar-III-141146",
    "BO-Malleswaram-III-141147",
    "BO-SRNagar-III-141148",
    "BO-Marathahalli-141149",
    "BO-Chikkabalapur-141150",
    "BO-Hospet-141151",
    "AO-Hubli-141200",
    "BO-Mangalore-141211",
    "BO-Hubli-141212",
    "BO-Belgaum-141213",
    "BO-Bellary-141214",
    "BO-Gulbarga-141215",
    "BO-Udupi-141220",
    "BO-Hassan-141221",
    "BO-Bantwal-141222",
    "BO-Moodabidri-141223",
    "BO-Mangalore-II-141224",
    "BO-Raichur-141225",
    "BO-Kundapur-141226",
    "RBO-Yadgir-614001",
    "RBO-Chikkodi-614002",
    "RBO-Channapatna-614003",
    "RBO-Bidar-614004",
    "RBO-Bijapur(Vijayapura)-614005",
    "RBO-Sirsi-614006",
    "RBO-Bagalkot-614007",
    "RBO-Gadag-614008",
    "RBO-Jamakhandi-614009",
    "RBO-Chamarajanagar-614010",
    "RBO-Ranebennur-614011",
    "RBO-Tiptur-614012",
    "RBO-Athani-614013",
    "RBO-Kumta-614014",
    "RBO-shiroor-614015",
    "RBO-Humnabad-614016",
    "Telesales-Bangalore-700019",
  ],
  Ahmedabad: [
    "ZO-Ahmedabad-171200",
    "BO-Baroda-171211",
    "BO-Rajkot-171212",
    "BO-Surat-171213",
    "BO-Maninagar-171214",
    "BO-Navrangpura-171215",
    "BO-Naroda-171216",
    "BO-Surat-II-171217",
    "BO-Mehsana-171218",
    "BO-Anand-171219",
    "BO-DirectAgents-171220",
    "BO-Bhavnagar-171221",
    "BO-Junagarh-171222",
    "BO-Palanpur-171223",
    "BO-Gandhidam-171224",
    "BO-Vapi-171225",
    "BO-Jamnagar-171226",
    "BO-Gandhinagar-171227",
    "BO-Himatnagar-171228",
    "BO-Nadiad-171229",
    "BO-Godhra-671200",
    "Telesales-Ahmedabad-700024",
  ],
  RanchiAO: [
    "BO-Ranchi-231112",
    "AO-Ranchi-231200",
    "BO-Jamshedpur-231211",
    "BO-Dhanbad-231212",
    "BO-Hazaribag-231215",
    "BO-Deogarh-231217",
    "RBO-Bokaro-623002",
    "RBO-Ramgarh-623004",
    "RBO-Daltonganj-623001",
    "RBO-Dumka-623014",
    "Telesales-Ranchi-700021",
  ],
};
